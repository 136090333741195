import { CategoriesEffects } from "@app/store/effects/categories.effect";
import { DiscountsEffects } from "@app/store/effects/discounts.effect";
import { GlobalsEffects } from "@app/store/effects/globals.effect";
import { OrderEffects } from "@app/store/effects/order.effect";
import { ProductsEffects } from "@app/store/effects/products.effect";
import { RollsOfDayEffects } from "@app/store/effects/rolls-of-day.effect";

export const productsEffects: any[] = [
  CategoriesEffects,
  ProductsEffects,
  OrderEffects,
  DiscountsEffects,
  RollsOfDayEffects,
  GlobalsEffects,
];
