import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { Router } from "@angular/router";
import * as fromStore from "@app/store";
import * as fromProductsSelectors from "@app/store/selectors/products.selectors";
import { select, Store } from "@ngrx/store";
import { Product } from "@shop-models/index";
import { ProductsService, ThemeService } from "@shop-services/index";
import { Observable } from "rxjs";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductComponent implements OnInit {
  @Input()
  id: string;

  product$: Observable<Product>;

  mouseOver: boolean;
  focus: boolean;

  isLiked$: Observable<boolean>;

  constructor(
    private store: Store<fromStore.ShopState>,
    private router: Router,
    private theme: ThemeService,
    private productsService: ProductsService,
    public mediaObserver: MediaObserver
  ) {}

  ngOnInit() {
    this.product$ = this.store.pipe(
      select(fromProductsSelectors.getProductById(this.id))
    );

    this.isLiked$ = this.store.pipe(
      select(fromProductsSelectors.getLikedProductById(this.id))
    );
  }

  openDetails(): void {
    this.router.navigate([], { queryParams: { productId: this.id } });
  }

  get isDarkTheme$(): Observable<boolean> {
    return this.theme.isDark$;
  }

  getCompressedImage(product: Product) {
    return this.productsService.getCompressedImagePath(product);
  }

  getOriginalImage(product: Product) {
    return this.productsService.getOriginalImagePath(product);
  }
}
