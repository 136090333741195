<div
  class="wrapper"
  (mouseover)="show = true"
  (mouseleave)="show = false"
  [class.selected]="(getSelectedCategoryRouteName$ | async) === element.url"
>
  <div [class]="'icon ' + 'origami-' + element.icon"></div>
  <div class="name" *ngIf="show" @board>
    <a class="title mat-title">{{ element.name }}</a>
  </div>
</div>
