import * as fromOrder from "@app/store/actions/order.action";

export interface OrderState {
  id: string;
  number: number;
  order: any;
  sending: boolean;
  sent: boolean;
  status: string;
  date: string;
  error: any;
}

export const initialState = {
  id: getOrderIdFromStorage() || null,
  number: 0,
  order: {},
  sending: false,
  sent: false,
  status: null,
  date: null,
  error: null,
};

export function reducer(
  state = initialState,
  action: fromOrder.OrderActions
): OrderState {
  switch (action.type) {
    case fromOrder.SEND_ORDER: {
      return {
        ...state,
        sending: true,
        sent: false,
        date: null,
      };
    }

    case fromOrder.SEND_ORDER_SUCCESS: {
      const order = action.payload;
      const newState = {
        ...state,
        id: order.id,
        number: order.number,
        sending: false,
        sent: true,
      };

      saveOrderIdToStorage(order.id);

      return newState;
    }

    case fromOrder.SEND_ORDER_FAIL: {
      const error = action.payload;

      return {
        ...state,
        error,
        sending: false,
        sent: false,
      };
    }

    case fromOrder.GET_ORDER: {
      return {
        ...state,
      };
    }

    case fromOrder.GET_ORDER_SUCCESS: {
      const order = action.payload;

      const newState = {
        ...state,
        number: order.frontpad_order_number,
        order,
      };

      return newState;
    }

    case fromOrder.GET_ORDER_FAIL: {
      const error = action.payload;
      console.error(error);
      return {
        ...state,
      };
    }

    case fromOrder.GET_ORDER_STATUS: {
      return {
        ...state,
        status: "Обновление статуса...",
        date: null,
      };
    }

    case fromOrder.GET_ORDER_STATUS_SUCCESS: {
      const status = action.payload.status;
      const date = action.payload.date;

      const newState = {
        ...state,
        status,
        date,
      };

      return newState;
    }

    case fromOrder.GET_ORDER_STATUS_FAIL: {
      const error = action.payload;
      console.error(error);

      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}

function saveOrderIdToStorage(id: string): void {
  try {
    localStorage?.setItem("orderId", id);
  } catch (error) {
    console.error(error);
  }
}

function getOrderIdFromStorage(): string {
  try {
    if (localStorage?.getItem("orderId")) {
      return localStorage?.getItem("orderId");
    }
  } catch (error) {
    console.error(error);
  }
}

export const getOrderId = (state: OrderState) => state.id;
export const getOrderNumber = (state: OrderState) => state.number;
export const getOrderEntity = (state: OrderState) => state.order;
