import { Component, OnDestroy, OnInit } from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { minOrderSum } from "@app/constants/constants";
import { Store, select } from "@ngrx/store";
import {
  LocalStorageService,
  ThemeService,
  UtilsService,
} from "@shop-services/index";
import * as fromStore from "@shop-store/index";
import * as fromOrderSelectors from "@shop-store/selectors/order.selectors";
import * as fromShopCartSelectors from "@shop-store/selectors/shop-cart.selectors";
import { Observable, Subscription } from "rxjs";

export class CustomValidationService {
  static numberLength(length): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value && (isNaN(c.value) || c.value.toString().length !== length)) {
        return { numberlength: true };
      }
      return null;
    };
  }
}

@Component({
  selector: "app-order-form",
  templateUrl: "./order-form.component.html",
  styleUrls: ["./order-form.component.scss"],
})
export class OrderFormComponent implements OnInit, OnDestroy {
  minOrderSum: number;

  cartSum$: Observable<number>;
  cartState: any;

  order$: Observable<any>;

  orderSent$: Observable<boolean>;
  orderError$: Observable<any>;
  orderSentSub: Subscription;

  orderSending$: Observable<boolean>;
  orderStatus$: Observable<string>;

  orderForm: FormGroup;

  name: AbstractControl;
  nameMinLength = 2;
  nameMaxLength = 50;

  phone: AbstractControl;
  phoneLength = 10;

  street: AbstractControl;
  streetMinLength = 2;
  streetMaxLength = 50;
  streetValidators = [
    Validators.required,
    Validators.minLength(this.streetMinLength),
    Validators.maxLength(this.streetMaxLength),
  ];

  houseNumber: AbstractControl;
  houseNumberMinLength = 1;
  houseNumberMaxLength = 50;
  houseNumberValidators = [
    Validators.required,
    Validators.minLength(this.houseNumberMinLength),
    Validators.maxLength(this.houseNumberMaxLength),
  ];

  entrance: AbstractControl;
  entranceMin = 1;
  entranceMax = 20;
  entranceValidators = [
    Validators.required,
    Validators.min(this.entranceMin),
    Validators.max(this.entranceMax),
  ];

  floor: AbstractControl;
  floorMin = 1;
  floorMax = 20;
  floorValidators = [
    Validators.required,
    Validators.min(this.floorMin),
    Validators.max(this.floorMax),
  ];

  apartment: AbstractControl;
  apartmentMaxLength = 50;
  apartmentValidators = [
    Validators.required,
    Validators.maxLength(this.apartmentMaxLength),
  ];

  persons: AbstractControl;
  personsMin = 1;
  personsMax = 99;

  pay: AbstractControl;

  exact: AbstractControl;

  change: AbstractControl;
  changeMin = 100;
  changeMax = 100000;

  comment: AbstractControl;
  commentMaxLength = 50;

  date: AbstractControl;
  today = new Date();

  // previously was: minDate set for 2 hours preorder gap.
  //Now: fixed for new datetime picker library so user can select today date
  minDate = new Date(this.today.getTime());
  maxDate = new Date(this.today.getTime() + 1000 * 60 * 60 * 24 * 29); // 29 days for preorder

  orderDateType: AbstractControl;

  orderType: AbstractControl;

  sending: boolean;

  orderIdSub: Subscription;
  orderSendingSub: Subscription;

  get isOnline$(): Observable<boolean> {
    return this.utils.online$;
  }

  constructor(
    public mediaObserver: MediaObserver,
    private store: Store<fromStore.ShopState>,
    private localStorageService: LocalStorageService,
    private fb: FormBuilder,
    private utils: UtilsService,
    private theme: ThemeService
  ) {
    this.minOrderSum = minOrderSum;

    const initialValues = JSON.parse(this.localStorageService.get("orderForm"));

    this.orderForm = this.fb.group({
      name: [
        initialValues ? initialValues.name : "",
        [
          Validators.required,
          Validators.minLength(this.nameMinLength),
          Validators.maxLength(this.nameMaxLength),
        ],
      ],
      phone: [
        initialValues ? initialValues.phone : null,
        [Validators.required, CustomValidationService.numberLength(10)],
      ],
      street: [
        initialValues ? initialValues.street : "",
        this.streetValidators,
      ],
      houseNumber: [
        initialValues ? initialValues.houseNumber : "",
        this.houseNumberValidators,
      ],
      entrance: [
        initialValues ? initialValues.entrance : null,
        this.entranceValidators,
      ],
      floor: [initialValues ? initialValues.floor : null, this.floorValidators],
      apartment: [
        initialValues ? initialValues.apartment : "",
        this.apartmentValidators,
      ],
      persons: [
        initialValues ? initialValues.persons : 1,
        [
          Validators.required,
          Validators.min(this.personsMin),
          Validators.max(this.personsMax),
        ],
      ],
      pay: [initialValues ? initialValues.pay : null, [Validators.required]],
      change: [
        null,
        [Validators.min(this.changeMin), Validators.max(this.changeMax)],
      ],
      exact: [false],
      comment: [
        initialValues ? initialValues.comment : "",
        [Validators.maxLength(this.commentMaxLength)],
      ],
      date: [this.minDate],
      orderDateType: ["now", [Validators.required]],
      orderType: ["delivery"],
    });

    this.order$ = this.store.pipe(select(fromOrderSelectors.getOrderState));
    this.orderSent$ = this.store.pipe(select(fromOrderSelectors.getOrderSent));

    this.orderError$ = this.store.pipe(
      select(fromOrderSelectors.getOrderError)
    );
    this.orderSending$ = this.store.pipe(
      select(fromOrderSelectors.getOrderSending)
    );
    this.orderStatus$ = this.store.pipe(
      select(fromOrderSelectors.getOrderStatus)
    );

    this.cartSum$ = this.store.pipe(select(fromShopCartSelectors.getCartSum));
    this.store
      .pipe(select(fromShopCartSelectors.getCartState))
      .subscribe((state) => {
        this.cartState = state;
      });

    this.orderSentSub = this.orderSent$.subscribe((sent) => {
      if (sent) {
        this.store.dispatch(new fromStore.ClearCart());
      }
    });

    this.name = this.orderForm.controls.name;
    this.phone = this.orderForm.controls.phone;
    this.street = this.orderForm.controls.street;
    this.houseNumber = this.orderForm.controls.houseNumber;
    this.entrance = this.orderForm.controls.entrance;
    this.floor = this.orderForm.controls.floor;
    this.apartment = this.orderForm.controls.apartment;
    this.persons = this.orderForm.controls.persons;
    this.pay = this.orderForm.controls.pay;
    this.change = this.orderForm.controls.change;
    this.exact = this.orderForm.controls.exact;
    this.comment = this.orderForm.controls.comment;
    this.orderDateType = this.orderForm.controls.orderDateType;
    this.date = this.orderForm.controls.date;
    this.orderType = this.orderForm.controls.orderType;

    this.orderForm.get("pay").valueChanges.subscribe((payMethod: string) => {
      if (payMethod === "1") {
        this.orderForm
          .get("change")
          .setValidators([
            Validators.required,
            Validators.min(this.changeMin),
            Validators.max(this.changeMax),
          ]);
        this.orderForm.get("change").updateValueAndValidity();
      } else {
        this.orderForm.get("change").clearValidators();
        this.orderForm
          .get("change")
          .setValidators([
            Validators.min(this.changeMin),
            Validators.max(this.changeMax),
          ]);
        this.orderForm.get("change").updateValueAndValidity();
      }
    });

    this.orderForm.get("exact").valueChanges.subscribe((exact: boolean) => {
      if (exact) {
        this.orderForm.get("change").clearValidators();
        this.orderForm.get("change").updateValueAndValidity();
        this.orderForm.controls[`change`].disable();
      } else {
        this.orderForm.controls[`change`].enable();
        this.orderForm
          .get("change")
          .setValidators([
            Validators.required,
            Validators.min(this.changeMin),
            Validators.max(this.changeMax),
          ]);
        this.orderForm.get("change").updateValueAndValidity();
      }
    });

    this.orderForm
      .get("orderDateType")
      .valueChanges.subscribe((orderDateType: string) => {
        if (orderDateType === "now") {
          this.orderForm.get("date").clearValidators();
          this.orderForm.get("date").updateValueAndValidity();
          this.orderForm.controls[`date`].disable();
        } else if (orderDateType === "preorder") {
          this.orderForm.controls[`date`].enable();
          this.orderForm.get("date").setValidators([Validators.required]);
          this.orderForm.get("date").updateValueAndValidity();
        }
      });

    this.orderForm
      .get("orderType")
      .valueChanges.subscribe((orderType: string) => {
        const fields = [
          "street",
          "houseNumber",
          "entrance",
          "floor",
          "apartment",
        ];
        if (orderType === "pickup") {
          fields.forEach((field) => {
            this.orderForm.get(field).clearValidators();
            this.orderForm.get(field).updateValueAndValidity();
            this.orderForm.controls[field].disable();
          });
        } else if (orderType === "delivery") {
          fields.forEach((field) => {
            this.orderForm.controls[field].enable();
          });

          this.orderForm.get("street").setValidators(this.streetValidators);
          this.orderForm
            .get("houseNumber")
            .setValidators(this.houseNumberValidators);
          this.orderForm.get("entrance").setValidators(this.entranceValidators);
          this.orderForm.get("floor").setValidators(this.floorValidators);
          this.orderForm
            .get("apartment")
            .setValidators(this.apartmentValidators);

          fields.forEach((field) => {
            this.orderForm.get(field).updateValueAndValidity();
          });
        }
      });

    this.orderForm.valueChanges.subscribe((val) => {
      this.localStorageService.save("orderForm", JSON.stringify(val));
    });
  }

  ngOnInit() {
    Object.values(this.orderForm.controls).forEach((control) => {
      control.markAsTouched();
    });
  }

  ngOnDestroy() {
    if (this.orderSendingSub) {
      this.orderSendingSub.unsubscribe();
    }
    if (this.orderIdSub) {
      this.orderIdSub.unsubscribe();
    }

    if (this.orderSentSub) {
      this.orderSentSub.unsubscribe();
    }
  }

  onSubmit(): void {
    if (this.orderForm.valid) {
      const order = new Object({
        products: this.cartState.entities,
        ...this.orderForm.value,
      });

      this.store.dispatch(new fromStore.SendOrder(order));

      this.orderSendingSub = this.store
        .pipe(select(fromOrderSelectors.getOrderSending))
        .subscribe((sending) => {
          this.sending = sending;
        });

      this.orderIdSub = this.store
        .pipe(select(fromOrderSelectors.getOrderId))
        .subscribe((id) => this.store.dispatch(new fromStore.GetOrder(id)));
    }
  }

  saveOrderIdToLocalStorage(id): void {
    this.localStorageService.save("orderId", id);
  }

  getOrderIdFromLocalStorage(id): string {
    return this.localStorageService.get("orderId");
  }

  get isDarkTheme$(): Observable<boolean> {
    return this.theme.isDark$;
  }
}
