import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { Sort } from "@app/constants/constants";
import * as fromStore from "@app/store";
import * as fromGlobalsSelectors from "@app/store/selectors/globals.selectors";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "app-sort-products",
  templateUrl: "./sort-products.component.html",
  styleUrls: ["./sort-products.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortProductsComponent implements OnInit {
  constructor(private store: Store<fromStore.ShopState>) {}

  ngOnInit() {}

  get SortEnum() {
    return Sort;
  }

  switchSort(sortType) {
    this.store.dispatch(new fromStore.SetProductsSort(sortType));
  }

  get currentSort$(): Observable<Sort> {
    return this.store.pipe(select(fromGlobalsSelectors.getGlobalsProductsSort));
  }
}
