<mat-card
  *ngIf="product$ | async as product"
  class="product-card"
  [routerLink]="[]"
  [queryParams]="{ productId: id }"
  [ngClass.lt-md]="{ 'lt-md': true }"
  (mouseenter)="mouseOver = true"
  (mouseleave)="mouseOver = false"
>
  <div class="image-wrapper">
    <img
      class="card-image"
      mat-card-image
      [src]="
        (isDarkTheme$ | async)
          ? getOriginalImage(product)
          : getCompressedImage(product)
      "
      [alt]="product.name"
    />
    <app-like-button
      [hidden]="
        !(isLiked$ | async) && !mouseOver && mediaObserver.isActive('gt-sm')
      "
      [liked]="isLiked$ | async"
      [id]="id"
      (click)="$event.stopPropagation()"
    ></app-like-button>
  </div>

  <mat-card-header>
    <mat-card-title>{{ product.name }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="product.description">
      <p class="description">
        {{ product.description }}
      </p>
    </ng-container>

    <ng-container *ngIf="product.tags">
      <mat-chip-list>
        <mat-chip *ngFor="let tag of product.tags" color="warn" selected="true"
          >{{ tag }}
        </mat-chip>
      </mat-chip-list>
    </ng-container>

    <ng-container *ngIf="product.weight">
      <p class="product-weight">{{ product.weight }} г</p>
    </ng-container>
  </mat-card-content>
  <mat-card-actions>
    <div>
      <ng-container
        *ngIf="product.originalPrice; then discountPrice; else defaultPrice"
      >
      </ng-container>
    </div>

    <ng-template #discountPrice>
      <span
        style="text-decoration: line-through; color: #bdbdbd"
        matTooltip="Скидка по акции Ролл дня"
        matTooltipPosition="above"
        >{{ product.originalPrice }}</span
      >
      <p class="product-price" style="margin-top: 0">{{ product.price }} ₽</p>
    </ng-template>

    <ng-template #defaultPrice>
      <p class="product-price">{{ product.price }} ₽</p>
    </ng-template>

    <div
      class="amount-control-wrapper"
      (click)="$event.stopPropagation()"
      (keyup.enter)="openDetails()"
    >
      <div style="transform-origin: center">
        <app-add-product-to-cart-button [product]="product">
        </app-add-product-to-cart-button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>
