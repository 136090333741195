import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Meta, Title } from "@angular/platform-browser";
import {
  companyName,
  companyPhoneNumberFormatted,
} from "@app/constants/constants";
import * as fromStore from "@app/store";
import * as fromDiscountsSelectors from "@app/store/selectors/discounts.selectors";
import { select, Store } from "@ngrx/store";
import { DiscountDetailsComponent } from "@shop-components/core/discounts/discount-details/discount-details.component";
import { Discount } from "@shop-models/discount.model";
import { UtilsService } from "@shop-services/utils.service";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-discounts",
  templateUrl: "./discounts.component.html",
  styleUrls: ["./discounts.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountsComponent implements OnInit {
  discounts$: Observable<Array<Discount>>;

  constructor(
    private dialog: MatDialog,
    private meta: Meta,
    private title: Title,
    private store: Store<fromStore.ShopState>,
    private utils: UtilsService
  ) {
    const pageTitle = `Акции и скидки - ${companyName} | ${companyPhoneNumberFormatted}`;

    this.title.setTitle(pageTitle);

    this.meta.updateTag({
      name: "og:title",
      content: pageTitle,
    });
  }

  ngOnInit() {
    this.utils.scrollTop();

    this.discounts$ = this.store.pipe(
      select(fromDiscountsSelectors.selectAll),
      map((discounts) => discounts.reverse())
    );
  }

  showDetails(discount: Discount) {
    this.dialog.open(DiscountDetailsComponent, {
      panelClass: "discount-details-dialog",
      autoFocus: false,
      data: {
        discount: discount,
      },
      width: "500px",
      height: "700px",
      maxWidth: "100vw",
      maxHeight: "100vh",
      backdropClass: "discount-details-dialog",
    });
  }
}
