import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";

@Injectable()
export class LocalStorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  save(key, value) {
    if (!key || !value) {
      return null;
    }
    if (isPlatformBrowser(this.platformId)) {
      try {
        localStorage?.setItem(key, value);
      } catch (error) {
        return null;
      }
    }
  }

  get(key) {
    if (!key) {
      return null;
    }
    if (isPlatformBrowser(this.platformId)) {
      try {
        return localStorage?.getItem(key);
      } catch (error) {
        return null;
      }
    }
  }
}
