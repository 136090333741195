import * as fromCategories from "@app/store/actions/categories.action";
import { createEntityAdapter } from "@ngrx/entity";
import { ProductsCategory } from "@shop-models/index";

// Entity adapter
export const categoryAdapter = createEntityAdapter<ProductsCategory>({
  selectId: (category: ProductsCategory) => category.route,
});
export interface CategoriesState {
  ids: Array<any>;
  entities: { [route: string]: ProductsCategory };
  loaded: boolean;
  loading: boolean;
}

export const initialCategoriesState: CategoriesState = {
  ids: [],
  entities: {},
  loaded: false,
  loading: false,
};

export const initialState: CategoriesState = categoryAdapter.getInitialState(
  initialCategoriesState
);

export function reducer(
  state: CategoriesState = initialState,
  action: fromCategories.CategoriesActions
): CategoriesState {
  switch (action.type) {
    case fromCategories.LOAD_CATEGORIES: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromCategories.LOAD_CATEGORIES_SUCCESS: {
      const newState = categoryAdapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
      });
      return newState;
    }

    case fromCategories.LOAD_CATEGORIES_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getCategoriesEntities = (state: CategoriesState) => state.entities;
export const getCategoriesLoading = (state: CategoriesState) => state.loading;
export const getCategoriesLoaded = (state: CategoriesState) => state.loaded;
