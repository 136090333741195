<ng-container *ngIf="rollsOfDayExists$ | async">
  <h2 class="mat-headline title">
    Роллы дня по {{ rollsOfDayPrice$ | async }} ₽
  </h2>
  <div class="products">
    <app-product
      [id]="product['id']"
      *ngFor="let product of rollsOfDayProducts$ | async"
    >
    </app-product>
  </div>
</ng-container>
