import { Theme } from "@app/constants/constants";
import * as fromFeature from "@app/store/reducers";
import * as fromGlobals from "@app/store/reducers/globals.reducer";
import { createSelector } from "@ngrx/store";

export const getGlobalsState = createSelector(
  fromFeature.getShopState,
  (state: fromFeature.ShopState) => state.globals
);

export const getGlobalsTheme = createSelector(
  getGlobalsState,
  fromGlobals.getGlobalsTheme
);

export const getGlobalsThemeIsDark = createSelector(
  getGlobalsTheme,
  (theme) => {
    if (theme === Theme.dark) {
      return true;
    }
  }
);

export const getGlobalsProductsSort = createSelector(
  getGlobalsState,
  fromGlobals.getGlobalsProductsSort
);
