import { NavElement } from "@shop-models//nav-element.model";
import { environment } from "environments/environment";

export const companyName = "Оригами";

export const companyPhoneNumberFormatted = "55-44-21";
export const companyPhoneNumber = "554421";
export const companyPhoneNumberFull = "+78212554421";
export const companyPhoneNumberFullFormatted = "+7 (8212) 55-44-21";

export const companyExtraPhoneNumberFormatted = "577-355";
export const companyExtraPhoneNumber = "577355";
export const companyExtraPhoneNumberFull = "+78212577355";
export const companyExtraPhoneNumberFullFormatted = "+7 (8212) 577-355";

export const minOrderSum = 800;

export const apiUrl: string = environment.production
  ? "https://origami11.ru/api/v2"
  : "http://localhost:1337/api/v2";
export const imagesUrl: string = environment.production
  ? "https://origami11.ru/images"
  : "http://localhost/images";

/* export const apiUrl: string = 'http://localhost:1337/api/v2';
export const imagesUrl: string = 'http://localhost/images'; */

/* export const apiUrl: string = 'https://origami11.ru/api/v2';
export const imagesUrl: string = 'https://origami11.ru/images';
 */

export const productFallbackImage = "assets/placeholders/sushi-1.svg";
export const productImagesUrl: string = imagesUrl + "/product/";
export const productOriginalImagesUrl: string =
  imagesUrl + "/product/original/";


export const metaTags = {
  title: `🍣 ${companyName} | Суши, роллы - доставка в Сыктывкаре | Телефон ${companyPhoneNumberFormatted}`,
  description: `${companyName} - Доставка роллов, суши, пиццы, удонов в Сыктывкаре. Здесь можно заказать вкусные суши, роллы, блюда японской, китайской кухни по приемлемым ценам на дом или в офис, а также с самовывозом. Минимальная доставка от ${minOrderSum} рублей! У нас действуют акции и скидки, а также скидки для постояяных клиентов.`, // tslint:disable:max-line-length
  tags:
    "доставка доставкой еды еду еда сыктывкар сыктывкаре заказать заказ онлайн роллы суши удон паста суп салаты японская китайская итальянская кухня", // tslint:disable:max-line-length
  phone: companyPhoneNumberFull,
  name: "Оригами",
  url: "https://origami11.ru",
  email: "origami_80@mail.ru",
  logo: "https://origami11.ru/favicon.png",
  country: "Russia",
} as const; 

export const navElements: Array<NavElement> = [
  {
    name: "Роллы",
    url: "rolls",
    icon: "rolls",
  },
  {
    name: "Роллы от шефа",
    url: "chef_rolls",
    icon: "chef_rolls",
  },
  {
    name: "Суши",
    url: "sushi",
    icon: "sushi",
    emoji: "🍣",
  },
  {
    name: "Сеты",
    url: "sets",
    icon: "sets",
    emoji: "🍱",
  },
  {
    name: "Запеченные роллы",
    url: "baked",
    icon: "baked",
  },
  {
    name: "Горячие роллы",
    url: "hot",
    icon: "hot_rolls",
  },
  {
    name: "Мини роллы",
    url: "mini",
    icon: "mini_rolls",
  },
  {
    name: "Пицца",
    url: "pizza",
    icon: "pizza",
    emoji: "🍕",
  },
  {
    name: "Острые роллы",
    url: "spicy",
    icon: "spicy_rolls",
    emoji: "🔥",
  },
  {
    name: "Роллы без риса",
    url: "no_rice",
    icon: "rolls_without_rice",
  },
  {
    name: "Сэндвич роллы",
    url: "sandwiches",
    icon: "sandwich_rolls",
  },
  {
    name: "Супы",
    url: "soups",
    icon: "soups",
    emoji: "🍜",
  },
  {
    name: "Итальянские пасты",
    url: "pastas",
    icon: "pasta",
    emoji: "🍝",
  },
  {
    name: "Удоны",
    url: "udons",
    icon: "udon",
  },
  {
    name: "Салаты",
    url: "salads",
    icon: "salad",
  },
  {
    name: "Горячие блюда",
    url: "hot_food",
    icon: "tempura",
  },
  {
    name: "Соусы",
    url: "sauces",
    icon: "sauces",
  },
  {
    name: "Напитки",
    url: "drink",
    icon: "water",
  },
];

export enum Theme {
  light,
  dark,
}

export enum Sort {
  ascName,
  descName,
  ascPrice,
  descPrice,
}
