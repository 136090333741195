import * as fromCategories from "@app/store/reducers/categories.reducer";
import * as fromDiscounts from "@app/store/reducers/discounts.reducer";
import * as fromGlobals from "@app/store/reducers/globals.reducer";
import * as fromOrder from "@app/store/reducers/order.reducer";
import * as fromProducts from "@app/store/reducers/products.reducer";
import * as fromRollsOfDay from "@app/store/reducers/rolls-of-day.reducer";
import * as fromShopCart from "@app/store/reducers/shop-cart.reducer";
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

export interface ShopState {
  productsCategories: fromCategories.CategoriesState;
  shopCart: fromShopCart.CartState;
  products: fromProducts.ProductsState;
  order: fromOrder.OrderState;
  discounts: fromDiscounts.DiscountsState;
  rollsOfDay: fromRollsOfDay.RollsOfDayState;
  globals: fromGlobals.GlobalsState;
}

export const productsReducers: ActionReducerMap<ShopState> = {
  productsCategories: fromCategories.reducer,
  shopCart: fromShopCart.reducer,
  products: fromProducts.reducer,
  order: fromOrder.reducer,
  discounts: fromDiscounts.reducer,
  rollsOfDay: fromRollsOfDay.reducer,
  globals: fromGlobals.reducer,
};

export const getShopState = createFeatureSelector<ShopState>("shop");

export const getCategoriesState = createSelector(
  getShopState,
  (state: ShopState) => state.productsCategories
);

export const getDiscountsState = createSelector(
  getShopState,
  (state: ShopState) => state.discounts
);

export const getRollsOfDayState = createSelector(
  getShopState,
  (state: ShopState) => state.rollsOfDay
);

export const getGlobalsState = createSelector(
  getShopState,
  (state: ShopState) => state.globals
);
