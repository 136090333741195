import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { navElements } from "@app/constants/constants";
import * as fromStore from "@app/store";
import * as fromCategoriesSelectors from "@app/store/selectors/categories.selectors";
import { select, Store } from "@ngrx/store";
import { NavElement } from "@shop-models/index";
import { Observable } from "rxjs";

@Component({
  selector: "app-mobile-navigation",
  templateUrl: "./mobile-navigation.component.html",
  styleUrls: ["./mobile-navigation.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavigationComponent implements OnInit {
  currentCategoryRoute$: Observable<string>;
  navMenuElements: Array<NavElement>;

  constructor(
    public mediaObserver: MediaObserver,
    private store: Store<fromStore.ShopState>
  ) {}

  ngOnInit() {
    this.navMenuElements = navElements;
    this.currentCategoryRoute$ = this.store.pipe(
      select(fromCategoriesSelectors.getSelectedCategoryRouteName)
    );
  }
}
