import { Injectable } from "@angular/core";
import * as categoriesActions from "@app/store/actions/categories.action";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { ProductsService } from "@shop-services/index";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

@Injectable()
export class CategoriesEffects {
  
  loadCategories$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(categoriesActions.LOAD_CATEGORIES))
    .pipe(
      switchMap(() => {
        return this.productsService.getCategories().pipe(
          map(
            (categories) =>
              new categoriesActions.LoadCategoriesSuccess(categories)
          ),
          catchError((error) =>
            of(new categoriesActions.LoadCategoriesFail(error))
          )
        );
      })
    ));

  constructor(
    private actions$: Actions,
    private productsService: ProductsService
  ) {}
}
