import { isPlatformBrowser } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  PLATFORM_ID,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import * as fromStore from "@app/store";
import * as fromCategoriesSelectors from "@app/store/selectors/categories.selectors";
import { Store, select } from "@ngrx/store";
import { ProductsCategory } from "@shop-models/index";
import { UtilsService } from "@shop-services/index";
import { Observable } from "rxjs";

@Component({
  selector: "app-products-list",
  templateUrl: "./products-list.component.html",
  styleUrls: ["./products-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListComponent {
  categoriesLoaded$: Observable<boolean>;
  selectedCategory$: Observable<ProductsCategory>;

  constructor(
    public mediaObserver: MediaObserver,
    private store: Store<fromStore.ShopState>,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ref: ChangeDetectorRef,
    private utils: UtilsService
  ) {
    this.mediaObserver.asObservable().subscribe(() => {
      this.ref.markForCheck();
    });

    if (isPlatformBrowser(this.platformId)) {
      this.selectedCategory$ = this.store.pipe(
        select(fromCategoriesSelectors.getSelectedCategory)
      );

      this.categoriesLoaded$ = this.store.pipe(
        select(fromCategoriesSelectors.getCategoriesLoaded)
      );
    }
  }

  get isTablet(): boolean {
    return this.utils.isTablet;
  }

  scrollTop(): void {
    this.utils.scrollTop();
  }
}
