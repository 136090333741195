import { Injectable } from "@angular/core";
import { Theme } from "@app/constants/constants";
import * as globalsActions from "@app/store/actions/globals.action";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { ThemeService } from "@shop-services/index";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

@Injectable()
export class GlobalsEffects {
  
  setTheme$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType<globalsActions.SetTheme>(globalsActions.SET_THEME))
    .pipe(
      switchMap((action) => {
        return this.theme.enableThemeByName(action.payload).pipe(
          map((theme: Theme) => new globalsActions.SetThemeSuccess(theme)),
          catchError((error) => of(new globalsActions.SetThemeFail(error)))
        );
      })
    ));

  constructor(private actions$: Actions, private theme: ThemeService) {}
}
