import { Sort, Theme } from "@app/constants/constants";
import { Action } from "@ngrx/store";

export const SET_PRODUCTS_SORT = "[Globals] Set products sort";
export const SET_THEME = "[Globals] Set theme";
export const SET_THEME_SUCCESS = "[Globals] Set theme success";
export const SET_THEME_FAIL = "[Globals] Set theme fail";

export class SetProductsSort implements Action {
  readonly type = SET_PRODUCTS_SORT;
  constructor(public payload: Sort) {}
}

export class SetTheme implements Action {
  readonly type = SET_THEME;
  constructor(public payload: Theme) {}
}

export class SetThemeSuccess implements Action {
  readonly type = SET_THEME_SUCCESS;
  constructor(public payload: any) {}
}

export class SetThemeFail implements Action {
  readonly type = SET_THEME_FAIL;
  constructor(public payload: any) {}
}

export type GlobalsActions =
  | SetProductsSort
  | SetTheme
  | SetThemeSuccess
  | SetThemeFail;
