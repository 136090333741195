<div
  style="
    display: flex;
    justify-content: center;
    -webkit-overflow-scrolling: touch !important;
    overflow-y: auto !important;
  "
>
  <iframe
    style="height: 100vh; width: 800px; max-width: 100vw; border: none"
    src="https://docs.google.com/document/d/e/2PACX-1vQ53pDZXdOHu3ml8iSMzjatJnf2w8mKi8WxLugWXKUrvRZw4FbLCwAT_MYMrIPnLPh5hEiBMfMo-iCb/pub?embedded=true"
  >
  </iframe>
</div>
