<div class="offline">
  <h2 class="mat-title">Вы не подключены к сети!</h2>
  <p class="mat-body-2">
    Без подключения к сети интернет цены в меню могут быть неактуальными, а
    также нельзя отправить заказ онлайн.
  </p>

  <p class="mat-body-2" style="margin-bottom: 0">
    Но вы можете оформить заказ по телефону
  </p>

  <h3 class="mat-title" style="margin: 10px">
    <strong style="letter-spacing: 1px">{{ phoneNumber }}</strong>
  </h3>

  <app-call-button style="margin-bottom: 20px"></app-call-button>
</div>
