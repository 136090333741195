import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as fromStore from "@app/store";
import * as fromShopCartSelectors from "@app/store/selectors/shop-cart.selectors";
import { Store, select } from "@ngrx/store";
import { OrderFormComponent } from "@shop-components/core/order/order-form/order-form.component";
import { ThemeService, UtilsService } from "@shop-services/index";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("visibilityChangedRotateFromBottom", [
      transition(":enter", [
        style({
          opacity: 0.7,
          transform: "translate(-50%, 10%) rotate3d(1, 0, 0, 85deg)",
        }),
        animate(
          250,
          style({
            opacity: 1,
            transform: "translate(-50%, 0) rotate3d(1, 1, 1, 0deg)",
          })
        ),
      ]),
      transition(":leave", [
        style({
          opacity: 1,
          transform: "translate(-50%, 0)",
        }),
        animate(
          150,
          style({
            opacity: 0.2,
            transform: "translate(-50%, 100%)", // 'transform-origin': 'bottom'
          })
        ),
      ]),
    ]),
    trigger("visibilityChangedRotateFromTop", [
      transition(":enter", [
        style({
          transform: "rotate3d(-1, 0, 0, 85deg) scale(0.1)",
        }),
        animate(
          200,
          style({
            transform: "rotate3d(1, 1, 1, 0deg) scale(1)",
          })
        ),
      ]),
      transition(":leave", [
        style({
          transform: "scale(1)",
        }),
        animate(
          120,
          style({
            transform: "scale(0.25)",
          })
        ),
      ]),
    ]),
    trigger("visibilityChangedFromBottom", [
      transition(":enter", [
        style({
          opacity: 0,
          transform: "translateY(50%)",
        }),
        animate(
          150,
          style({
            opacity: 1,
            transform: "translateY(0)",
          })
        ),
      ]),
      transition(":leave", [
        style({
          opacity: 1,
          transform: "translateY(0)",
        }),
        animate(
          150,
          style({
            opacity: 0,
            transform: "translateY(50%)",
          })
        ),
      ]),
    ]),
    trigger("visibilityChangedFromTop", [
      transition(":enter", [
        style({
          opacity: 0,
          transform: "translateY(-50%)",
        }),
        animate(
          150,
          style({
            opacity: 1,
            transform: "translateY(0)",
          })
        ),
      ]),
      transition(":leave", [
        style({
          opacity: 1,
          transform: "translateY(0)",
        }),
        animate(
          150,
          style({
            opacity: 0,
            transform: "translateY(-50%)",
          })
        ),
      ]),
    ]),
    trigger("cartState", [
      state(
        "inactive",
        style({
          transform: "translateY(0)",
        })
      ),
      state(
        "activeIncrement",
        style({
          transform: "scale(1.4)",
        })
      ),
      transition("inactive => activeIncrement", animate("250ms ease-in")),
      transition("* => inactive", animate("100ms")),
    ]),
  ],
})
export class CartComponent implements OnInit, OnDestroy {
  public state = "";

  cartAnimState = "inactive";

  prevCount: number; // tracks current cart count

  cart$: Observable<any>;
  cartSum$: Observable<number>;
  cartWeight$: Observable<number>;
  cartCount$: Observable<number>;
  cartCountSub: Subscription;
  show: boolean;

  screen$: Observable<MediaChange[]>;

  constructor(
    public mediaObserver: MediaObserver,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<fromStore.ShopState>,
    private theme: ThemeService,
    private ref: ChangeDetectorRef,
    private utils: UtilsService
  ) {
    this.screen$ = this.mediaObserver.asObservable();
    this.screen$.subscribe(() => this.ref.markForCheck());

    this.cart$ = this.store.pipe(select(fromShopCartSelectors.getCartState));
    this.cartSum$ = this.store.pipe(select(fromShopCartSelectors.getCartSum));
    this.cartWeight$ = this.store.pipe(
      select(fromShopCartSelectors.getCartWeight)
    );
    this.cartCount$ = this.store.pipe(
      select(fromShopCartSelectors.getCartCount)
    );
  }

  ngOnInit() {
    this.cartCountSub = this.cartCount$.subscribe((count) => {
      if (this.cartAnimState === "inactive" && this.prevCount) {
        if (count - this.prevCount > 0) {
          this.cartAnimState = "activeIncrement";
        }

        if (count - this.prevCount < 0) {
          this.cartAnimState = "activeDecrement";
        }
      }
      this.prevCount = count;
      this.ref.detectChanges(); // fix for ExpressionChangedAfterItHasBeenCheckedError
    });
  }

  animEnd(): void {
    this.cartAnimState = "inactive";
  }

  ngOnDestroy() {
    if (this.cartCountSub) {
      this.cartCountSub.unsubscribe();
    }
  }

  openOrderDialog() {
    this.dialog.open(OrderFormComponent, {
      width: "400px",
      height: "650px",
      maxWidth: "100vw",
      maxHeight: "100vh",
      id: "order-form",
    });
  }

  toggle(): void {
    this.show = !this.show;
  }

  clearCart(): void {
    this.store.dispatch(new fromStore.ClearCart());
    this.undoClearSnackBar();
    this.show = false;
  }

  undoClearSnackBar(): void {
    this.snackBar
      .open("Корзина очищена", "ОТМЕНИТЬ", {
        duration: 7000,
      })
      .onAction()
      .subscribe(() => {
        this.undoClearCart();
      });
  }
  undoClearCart(): void {
    this.store.dispatch(new fromStore.UndoClearCart());
    this.show = true;
  }

  get isDarkTheme$(): Observable<boolean> {
    return this.theme.isDark$;
  }

  get isTablet(): boolean {
    return this.utils.isTablet;
  }
}
