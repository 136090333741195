import { ChangeDetectionStrategy, Component } from "@angular/core";
import { navElements } from "@app/constants/constants";
import { NavElement } from "@shop-models/nav-element.model";

@Component({
  selector: "app-sidenav-menu",
  templateUrl: "./sidenav-menu.component.html",
  styleUrls: ["./sidenav-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavMenuComponent {
  elements: Array<NavElement>;
  constructor() {
    this.elements = navElements;
  }
}
