import * as fromRoot from "@app/router/store/reducers";
import * as fromFeature from "@app/store/reducers";
import * as fromCategories from "@app/store/reducers/categories.reducer";
import { createSelector } from "@ngrx/store";
import { Product } from "@shop-models/product.model";
import { ProductsCategory } from "@shop-models/products-category.model";

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = fromCategories.categoryAdapter.getSelectors(fromFeature.getCategoriesState);

export const getCategoriesListEntities = createSelector(
  fromFeature.getCategoriesState,
  fromCategories.getCategoriesEntities
);

export const getSelectedCategory = createSelector(
  selectEntities,
  fromRoot.getRouterState,
  (entities, router): ProductsCategory => {
    if (router.state && entities[router.state.params.categoryId]) {
      return entities[router.state.params.categoryId];
    } else {
    }
  }
);

export const getSelectedCategoryProducts = createSelector(
  selectEntities,
  fromRoot.getRouterState,
  (entities, router): Product[] => {
    if (router.state && entities[router.state.params.categoryId]) {
      const products = entities[router.state.params.categoryId].products;
      products.sort(function (a, b) {
        return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
      });

      return products;
    } else {
    }
  }
);

export const getSelectedCategoryId = createSelector(
  getSelectedCategory,
  (category: ProductsCategory) => {
    return category.id;
  }
);

export const getSelectedCategoryRouteName = createSelector(
  getSelectedCategory,
  (category: ProductsCategory): string => {
    if (category) {
      return category.route || null;
    }
  }
);

export const getSelectedCategoryName = createSelector(
  getSelectedCategory,
  (category: ProductsCategory): string => {
    return category ? category.name : null;
  }
);

export const getCategoryById = (id) =>
  createSelector(
    selectEntities,
    (entities): ProductsCategory => {
      return entities[id];
    }
  );

export const getCategoryEntitiesByRouteName = (route) =>
  createSelector(selectEntities, (entities): any => {
    return entities[route].products;
  });

export const getCategoryEntitiesSliceById = (id, start, end) =>
  createSelector(
    getSelectedCategoryProducts,
    (entities): Array<Product> => {
      if (id && entities) {
        const slice = entities.slice(start, end);
        return slice;
      }
    }
  );

export const getCategoriesList = createSelector(selectEntities, (entities) => {
  return Object.values(entities);
});

export const getCategoriesLoading = createSelector(
  fromFeature.getCategoriesState,
  fromCategories.getCategoriesLoading
);

export const getCategoriesLoaded = createSelector(
  fromFeature.getCategoriesState,
  fromCategories.getCategoriesLoaded
);
