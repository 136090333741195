import { Action } from "@ngrx/store";
import { Product } from "@shop-models/product.model";

export const ADD_TO_CART = "[Cart] Add product to cart";
export const REMOVE_FROM_CART = "[Cart] Remove product from cart";
export const CLEAR_CART = "[Cart] Clear cart";
export const UNDO_CLEAR_CART = "[Cart] Undo clearing cart";
export const GET_CART = "[Cart] Get cart";
export const SET_DISCOUNT = "[Cart] Set discount";

export class AddToCart implements Action {
  readonly type = ADD_TO_CART;

  constructor(public payload: Product) {}
}

export class RemoveFromCart implements Action {
  readonly type = REMOVE_FROM_CART;

  constructor(public payload: Product) {}
}

export class ClearCart implements Action {
  readonly type = CLEAR_CART;
}

export class UndoClearCart implements Action {
  readonly type = UNDO_CLEAR_CART;
}

export type CartActions =
  | AddToCart
  | RemoveFromCart
  | ClearCart
  | UndoClearCart;
