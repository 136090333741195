import { animate, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import * as fromStore from "@app/store";
import * as fromShopCartSelectors from "@app/store/selectors/shop-cart.selectors";
import { select, Store } from "@ngrx/store";
import { Product } from "@shop-models/product.model";
import { ThemeService } from "@shop-services/index";
import { Observable } from "rxjs";

@Component({
  selector: "app-add-product-to-cart-button",
  templateUrl: "./add-product-to-cart-button.component.html",
  styleUrls: ["./add-product-to-cart-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("appearance", [
      transition(":enter", [
        style({
          opacity: 0.6,
          transform: "scale(0.75)",
        }),
        animate(
          "250ms ease-out",
          style({
            opacity: 1,
            transform: "scale(1)",
          })
        ),
      ]),
    ]),
  ],
})
export class AddProductToCartComponent implements OnInit {
  @Input()
  product: Product;

  amount$: Observable<number>;

  constructor(
    private store: Store<fromStore.ShopState>,
    private theme: ThemeService
  ) {}

  ngOnInit() {
    this.amount$ = this.store.pipe(
      select(fromShopCartSelectors.getProductCountById(this.product.id))
    );
  }

  addToCart() {
    this.store.dispatch(new fromStore.AddToCart(this.product));
  }
  removeFromCart() {
    this.store.dispatch(new fromStore.RemoveFromCart(this.product));
  }

  get isDarkTheme$() {
    return this.theme.isDark$;
  }
}
