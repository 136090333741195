<ng-container *ngIf="!(amount$ | async)">
  <button
    (click)="addToCart()"
    mat-stroked-button
    color="primary"
    style="padding: 7px 20px; font-size: 16px"
    [class.origami-dark-theme]="isDarkTheme$ | async"
  >
    <mat-icon style="margin-right: 5px">add_shopping_cart</mat-icon>
    В корзину
  </button>
</ng-container>

<ng-container *ngIf="amount$ | async as amount">
  <div class="counter" @appearance>
    <button
      (click)="removeFromCart()"
      mat-icon-button
      color="primary"
      style="margin-top: 5px"
    >
      <mat-icon>remove</mat-icon>
    </button>

    <p class="amount">{{ amount }}</p>

    <button
      (click)="addToCart()"
      mat-icon-button
      color="primary"
      style="margin-top: 5px"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</ng-container>
