import { ModuleWithProviders } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AboutComponent } from "@app/components/core/about/about.component";
import { HomeComponent } from "@app/components/core/home/home.component";
import { DiscountsComponent } from "@shop-components/core/discounts/discounts.component";
import { MainComponent } from "@shop-components/core/main/main.component";
import { OrderInfoComponent } from "@shop-components/core/order/order-info/order-info.component";
import { ProductListComponent } from "@shop-components/core/products-list/products-list.component";
import { PrivacyComponent } from "./components/core/privacy/privacy.component";

export const router: Routes = [
  /*     {
            path: 'home',
            loadChildren: '@app/home/home.module#HomeModule'
        },
        {
            path: 'lazy',
            loadChildren: '@app/lazy/lazy.module#LazyModule'
        }, */
  {
    path: "admin",
    loadChildren: () =>
      import("@shop-admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
      },
      {
        path: "about",
        component: AboutComponent,
      },
      {
        path: "privacy",
        component: PrivacyComponent,
      },
      {
        path: "discounts",
        component: DiscountsComponent,
      },
      {
        path: "myOrders",
        component: OrderInfoComponent,
      },
      {
        path: ":categoryId",
        component: ProductListComponent,
      },
    ],
  },
];
export const appRouter: ModuleWithProviders<RouterModule> = RouterModule.forRoot(
  router,
  {
    initialNavigation: "enabledBlocking",
    scrollPositionRestoration: "top",
    relativeLinkResolution: "legacy",
  }
);
