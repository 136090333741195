import { Action } from "@ngrx/store";
import { Discount } from "@shop-models/discount.model";

export const LOAD_DISCOUNTS_LIST = "[Discounts] Load discounts";
export const LOAD_DISCOUNTS_LIST_FAIL = "[Discounts] Load discounts fail";
export const LOAD_DISCOUNTS_LIST_SUCCESS = "[Discounts] Load discounts success";

export class LoadDiscounts implements Action {
  readonly type = LOAD_DISCOUNTS_LIST;
}

export class LoadDiscountsFail implements Action {
  readonly type = LOAD_DISCOUNTS_LIST_FAIL;
  constructor(public payload: any) {}
}

export class LoadDiscountsSuccess implements Action {
  readonly type = LOAD_DISCOUNTS_LIST_SUCCESS;
  constructor(public payload: Array<Discount>) {}
}

export type DiscountsActions =
  | LoadDiscounts
  | LoadDiscountsFail
  | LoadDiscountsSuccess;
