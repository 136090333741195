import { animate, style, transition, trigger } from "@angular/animations";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import * as fromStore from "@app/store";
import * as fromCategorySelectors from "@app/store/selectors/categories.selectors";
import { select, Store } from "@ngrx/store";
import { NavElement } from "@shop-models/nav-element.model";
import { Observable } from "rxjs";

@Component({
  selector: "app-sidenav-element",
  templateUrl: "./sidenav-element.component.html",
  styleUrls: ["./sidenav-element.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("board", [
      transition(":enter", [
        style({
          transform: "translateX(-4%)",
        }),
        animate(
          250,
          style({
            transform: "translateX(0)",
          })
        ),
      ]),
    ]),
  ],
})
export class SidenavElementComponent {
  @Input()
  element: NavElement;

  show: boolean;

  getSelectedCategoryRouteName$: Observable<string>;

  constructor(private store: Store<fromStore.ShopState>) {
    this.getSelectedCategoryRouteName$ = this.store.pipe(
      select(fromCategorySelectors.getSelectedCategoryRouteName)
    );
  }
}
