import { Injectable } from "@angular/core";
import * as orderActions from "@app/store/actions/order.action";
import {
  GetOrder,
  GetOrderStatus,
  SendOrder
} from "@app/store/actions/order.action";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ApiService } from "@shop-services/index";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

@Injectable()
export class OrderEffects {
  
  sendOrder$ = createEffect(() => this.actions$
    .pipe(ofType<SendOrder>(orderActions.SEND_ORDER))
    .pipe(
      switchMap((action) => {
        return this.apiService.sendOrder(action.payload).pipe(
          map(
            (createdOrder) => new orderActions.SendOrderSuccess(createdOrder)
          ),
          catchError((error) => of(new orderActions.SendOrderFail(error)))
        );
      })
    ));

  
  getOrder$ = createEffect(() => this.actions$.pipe(ofType<GetOrder>(orderActions.GET_ORDER)).pipe(
    switchMap((action) => {
      return this.apiService.getOrder(action.payload).pipe(
        map((order) => new orderActions.GetOrderSuccess(order)),
        catchError((error) => of(new orderActions.GetOrderFail(error)))
      );
    })
  ));

  
  getOrderStatus$ = createEffect(() => this.actions$
    .pipe(ofType<GetOrderStatus>(orderActions.GET_ORDER_STATUS))
    .pipe(
      switchMap((action) => {
        return this.apiService.getOrderStatus(action.payload).pipe(
          map((status) => new orderActions.GetOrderStatusSuccess(status)),
          catchError((error) => of(new orderActions.GetOrderStatusFail(error)))
        );
      })
    ));

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
  ) {}
}
