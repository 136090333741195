<div
  class="wrapper"
  [ngClass]="{ 'lt-md': mediaObserver.isActive('lt-md') || isTablet }"
>
  <!-- CART BUTTON -->
  <ng-container
    *ngIf="
      mediaObserver.isActive('lt-md') || isTablet;
      then mobileButton;
      else pcButton
    "
  >
  </ng-container>

  <!-- CART LIST -->
  <ng-container
    *ngIf="
      mediaObserver.isActive('lt-md') || isTablet;
      then mobileList;
      else pcList
    "
  >
  </ng-container>

  <!-- MOBILE TEMPLATE -->
  <ng-template #mobileButton>
    <button
      *ngIf="(cart$ | async) && (cartCount$ | async)"
      (click)="toggle()"
      mat-fab
      color="accent"
      class="cart-button"
      [@visibilityChangedFromBottom]
      [matBadge]="cartCount$ | async"
      matBadgePosition="above after"
      matBadgeColor="warn"
      matBadgeSize="medium"
      [matBadgeHidden]="show"
    >
      <mat-icon
        *ngIf="!show"
        class="cart-button-icon"
        [@cartState]="cartAnimState"
        (@cartState.done)="animEnd()"
        >shopping_cart</mat-icon
      >
      <mat-icon *ngIf="show" class="cart-button-icon">expand_more</mat-icon>
    </button>
  </ng-template>
  <ng-template #mobileList>
    <div
      [ngClass]="{ dark: isDarkTheme$ | async }"
      class="cart-wrapper"
      *ngIf="(cartCount$ | async) && show"
      [@visibilityChangedRotateFromBottom]
    >
      <mat-card class="cart">
        <mat-card-content>
          <app-cart-list></app-cart-list>
        </mat-card-content>
        <div class="sum">
          <strong>Итого: </strong>
          <strong>{{ cartSum$ | async }} ₽</strong>
          <strong>+ 50₽ доставка</strong>
        </div>
        <div class="weight" *ngIf="(cartWeight$ | async) > 0">
          Вес корзины:
          <strong>{{ cartWeight$ | async }} г</strong>
        </div>
        <div class="actions">
          <button mat-icon-button (click)="clearCart()">
            <span
              matTooltip="Удалить всё из корзины"
              matTooltipPosition="above"
            >
              <mat-icon>remove_shopping_cart</mat-icon>
            </span>
          </button>
          <button
            color="accent"
            (click)="openOrderDialog(); toggle()"
            mat-raised-button
          >
            <h3 class="mat-h3 order-button">Оформить заказ</h3>
          </button>
        </div>
      </mat-card>
    </div>
  </ng-template>

  <!-- PC TEMPLATE -->
  <ng-template #pcButton>
    <button
      *ngIf="(cart$ | async) && (cartCount$ | async)"
      (click)="toggle()"
      mat-fab
      color="accent"
      [@visibilityChangedFromTop]
      matBadge="{{ cartCount$ | async }}"
      matBadgePosition="below before"
      matBadgeColor="warn"
      matBadgeSize="medium"
      matBadgeOverlap="true"
      [matBadgeHidden]="show"
    >
      <mat-icon
        class="cart-icon"
        [@cartState]="cartAnimState"
        (@cartState.done)="animEnd()"
        >shopping_cart</mat-icon
      >
    </button>
  </ng-template>
  <ng-template #pcList>
    <div
      [ngClass]="{ dark: isDarkTheme$ | async }"
      class="cart-wrapper"
      *ngIf="(cartCount$ | async) && show"
      [@visibilityChangedRotateFromTop]
    >
      <mat-card class="cart">
        <mat-card-content>
          <app-cart-list></app-cart-list>
        </mat-card-content>
        <div class="sum">
          <strong>Итого: </strong>
          <strong>{{ cartSum$ | async }} ₽</strong>
        </div>
        <div class="fuel"><strong>+ 50₽ доставка</strong></div>
        <div class="weight" *ngIf="(cartWeight$ | async) > 0">
          Вес корзины:
          <strong>{{ cartWeight$ | async }} г</strong>
        </div>
        <div class="actions">
          <button mat-icon-button (click)="clearCart()">
            <span
              matTooltip="Удалить всё из корзины"
              matTooltipPosition="above"
            >
              <mat-icon>remove_shopping_cart</mat-icon>
            </span>
          </button>
          <button
            color="accent"
            (click)="openOrderDialog(); toggle()"
            mat-raised-button
          >
            <h3 class="mat-h3 order-button">Оформить заказ</h3>
            <!-- <mat-icon>monetization_on</mat-icon> -->
          </button>
        </div>
      </mat-card>
    </div>
  </ng-template>
</div>
