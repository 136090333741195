import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import * as fromStore from "@app/store";
import * as fromOrderSelectors from "@app/store/selectors/order.selectors";
import { select, Store } from "@ngrx/store";
import { Product } from "@shop-models/product.model";
import { ProductsService, ThemeService } from "@shop-services/index";
import { interval, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-order-info",
  templateUrl: "./order-info.component.html",
  styleUrls: ["./order-info.component.scss"],
})
export class OrderInfoComponent implements OnInit, OnDestroy {
  displayedColumns = ["img", "name", "price", "amount", "sum"];
  dataSource: any;

  @ViewChild(MatSort)
  sort: MatSort;

  orderState$: Observable<any>;
  id: string;

  shopCart: any;

  private readonly ORDER_STATUS_UPDATE_INTERVAL = 30000;
  private destroy$ = new Subject();

  constructor(
    private store: Store<fromStore.ShopState>,
    public mediaObserver: MediaObserver,
    private theme: ThemeService,
    private productsService: ProductsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.orderState$ = this.store.pipe(
      select(fromOrderSelectors.getOrderState)
    );

    this.orderState$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.id = state.id;

      this.shopCart = state.order.shopCart;

      this.dataSource = new MatTableDataSource(this.products);
      this.dataSource.sort = this.sort;
    });

    interval(this.ORDER_STATUS_UPDATE_INTERVAL)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.refreshOrderStatus());
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  get products(): Array<any> {
    if (this.shopCart) {
      const ids = Object.keys(this.shopCart);

      const products: any = [];

      ids.map((id) => {
        products.push({
          ...this.shopCart[id].product,
          amount: this.shopCart[id].amount,
        });
      });

      return products;
    }
  }

  get sum(): number {
    if (this.products) {
      let sum = 0;
      this.products.forEach((prod) => {
        sum = sum + prod.amount * prod.price;
      });

      return sum;
    }
  }

  get isDarkTheme$(): Observable<boolean> {
    return this.theme.isDark$;
  }

  refreshOrderStatus(): void {
    if (!this.id) {
      return;
    }
    this.store.dispatch(new fromStore.GetOrderStatus(this.id));
  }

  getCompressedImage(product: Product): string {
    return this.productsService.getCompressedImagePath(product);
  }

  getOriginalImage(product: Product): string {
    return this.productsService.getOriginalImagePath(product);
  }

  openProductDetails(product: Product): void {
    this.router.navigate([], { queryParams: { productId: product.id } });
  }
}
