<div class="wrapper" *ngIf="discounts$ | async as discounts">
  <h1 class="mat-display-1">Акции и скидки на доставку и самовывоз</h1>
  <div class="discounts">
    <div
      class="discount"
      *ngFor="let discount of discounts"
      (click)="showDetails(discount)"
    >
      <img *ngIf="discount.img" [src]="discount.img" />
    </div>
  </div>
</div>
