import * as fromCart from "@app/store/actions/shop-cart.action";
import { CartItem } from "@shop-models/cart-item.model";

export interface CartState {
  entities: { [id: number]: CartItem };
  sum: number;
  count: number;
}

export const initialState = getCartFromStorage()
  ? getCartFromStorage()
  : {
      entities: {},
      sum: 0,
      count: 0,
    };

export function reducer(
  state = initialState,
  action: fromCart.CartActions
): CartState {
  switch (action.type) {
    case fromCart.ADD_TO_CART: {
      const product = action.payload;
      const sum = state.sum + action.payload.price;
      const count = state.count + 1;
      const id = action.payload.id;
      let amount = 1;

      if (state.entities[id]) {
        amount = state.entities[id].amount + 1;
      }

      const entities = {
        ...state.entities,
        [id]: { product, amount },
      };

      const newState = {
        ...state,
        entities,
        sum,
        count,
      };

      saveCartToStorage(newState);

      return newState;
    }

    case fromCart.REMOVE_FROM_CART: {
      const id = action.payload.id;
      let sum: number;
      let amount = 1;
      let entities;
      const count = state.count - 1;

      if (state.entities[id]) {
        amount =
          state.entities[id].amount > 1 ? state.entities[id].amount - 1 : 0;

        entities = {
          ...state.entities,
          [id]: { product: state.entities[id].product, amount },
        };

        if (amount <= 0) {
          delete entities[id];
        }
      } else {
        entities = {
          ...state.entities,
          [id]: { product: state.entities[id].product, amount },
        };
      }

      if (Object.keys(entities).length < 1) {
        sum = 0;
      } else {
        sum = state.sum - action.payload.price;
      }

      const newState = {
        ...state,
        entities,
        sum,
        count,
      };

      saveCartToStorage(newState);

      return newState;
    }

    case fromCart.CLEAR_CART: {
      backupCartToStorage(state);
      clearCartFromStorage();

      const newState = {
        ...state,
        entities: {},
        sum: 0,
        count: 0,
      };

      return newState;
    }

    case fromCart.UNDO_CLEAR_CART: {
      const backupCart = getCartBackupFromStorage();
      saveCartToStorage(backupCart);
      return backupCart;
    }

    default: {
      return state;
    }
  }
}

function backupCartToStorage(cartState: CartState): void {
  try {
    localStorage?.setItem("cartBackup", JSON.stringify(cartState));
  } catch (error) {
    return;
  }
}

function getCartBackupFromStorage(): CartState {
  try {
    if (!localStorage?.getItem("cartBackup")) {
      return initialState;
    }
    return JSON.parse(localStorage?.getItem("cartBackup"));
  } catch (error) {
    return null;
  }
}

function clearCartFromStorage() {
  try {
    localStorage?.removeItem("cart");
  } catch (error) {
    return;
  }
}

function saveCartToStorage(cartState: CartState): void {
  try {
    localStorage?.setItem("cart", JSON.stringify(cartState));
  } catch (error) {
    return;
  }
}

function getCartFromStorage(): CartState {
  try {
    if (!localStorage?.getItem("cart")) {
      return initialState;
    }
    return JSON.parse(localStorage?.getItem("cart"));
  } catch (error) {
    return null;
  }
}

export const getShopCartEntities = (state: CartState) => state.entities;
export const getShopCartSum = (state: CartState) => state.sum;
