import * as fromRollsOfDay from "@app/store/actions/rolls-of-day.action";

export interface RollsOfDayState {
  price?: number;
  products?: Array<string>;
  loaded: boolean;
  loading: boolean;
}

export const initialState: RollsOfDayState = {
  price: null,
  products: [],
  loaded: false,
  loading: false,
};

export function reducer(
  state = initialState,
  action: fromRollsOfDay.RollsOfDayActions
): RollsOfDayState {
  switch (action.type) {
    case fromRollsOfDay.LOAD_ROLLSOFDAY: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromRollsOfDay.LOAD_ROLLSOFDAY_SUCCESS: {
      const rollsOfDay = action.payload;

      let newState = {
        loaded: true,
        loading: false,
        price: null,
        products: null,
      };

      if (rollsOfDay) {
        newState = {
          ...newState,
          price: rollsOfDay.price,
          products: rollsOfDay.products,
        };
      }

      return newState;
    }

    case fromRollsOfDay.LOAD_ROLLSOFDAY_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    default: {
      return state;
    }
  }
}
export const getRollsOfDayProducts = (state: RollsOfDayState) => state.products;
export const getRollsOfDayPrice = (state: RollsOfDayState) => state.price;
export const getRollsOfDayLoading = (state: RollsOfDayState) => state.loading;
export const getRollsOfDayLoaded = (state: RollsOfDayState) => state.loaded;
