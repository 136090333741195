import * as fromDiscounts from "@app/store/actions/discounts.action";
import { createEntityAdapter } from "@ngrx/entity";
import { Discount } from "@shop-models/index";

// Entity adapter
export const discountsAdapter = createEntityAdapter<Discount>({
  selectId: (discount: Discount) => discount.id,
});

export interface DiscountsState {
  ids: Array<any>;
  entities: { [id: string]: Discount };
  loaded: boolean;
  loading: boolean;
}

export const initialDiscountsState: DiscountsState = {
  ids: [],
  entities: {},
  loaded: false,
  loading: false,
};

export const initialState: DiscountsState = discountsAdapter.getInitialState(
  initialDiscountsState
);

export function reducer(
  state = initialState,
  action: fromDiscounts.DiscountsActions
): DiscountsState {
  switch (action.type) {
    case fromDiscounts.LOAD_DISCOUNTS_LIST: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromDiscounts.LOAD_DISCOUNTS_LIST_SUCCESS: {
      const newState = discountsAdapter.setAll(action.payload, {
        ...state,
        loaded: true,
        loading: false,
      });
      return newState;
    }

    case fromDiscounts.LOAD_DISCOUNTS_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    default: {
      return state;
    }
  }
}
export const getDiscountsEntities = (state: DiscountsState) => state.entities;
export const getDiscountsLoading = (state: DiscountsState) => state.loading;
export const getDiscountsLoaded = (state: DiscountsState) => state.loaded;
