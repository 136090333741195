import { OverlayContainer } from "@angular/cdk/overlay";
import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Theme } from "@app/constants/constants";
import * as fromStore from "@app/store";
import * as fromGlobalsSelectors from "@app/store/selectors/globals.selectors";
import { select, Store } from "@ngrx/store";
import { LocalStorageService } from "@shop-services/localstorage.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ThemeService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private overlayContainer: OverlayContainer,
    private store: Store<fromStore.ShopState>,
    private localStorageService: LocalStorageService
  ) {}

  enableDarkTheme(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.overlayContainer
      .getContainerElement()
      .classList.add("origami-dark-theme");
    this.localStorageService.save("dark", "true");
  }

  enableLightTheme(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    this.overlayContainer
      .getContainerElement()
      .classList.remove("origami-dark-theme");
    this.localStorageService.save("dark", "false");
  }

  enableThemeByName(name: Theme): Observable<Theme> {
    if (name === Theme.light) {
      this.enableLightTheme();
      return new Observable((observer) => {
        observer.next(Theme.light);
      });
    } else if (name === Theme.dark) {
      this.enableDarkTheme();
      return new Observable((observer) => {
        observer.next(Theme.dark);
      });
    } else {
      return new Observable((observer) => {
        observer.next(Theme.light);
      });
    }
  }

  get isDark$(): Observable<boolean> {
    return this.store.pipe(select(fromGlobalsSelectors.getGlobalsThemeIsDark));
  }
}
