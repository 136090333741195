import { Injectable } from "@angular/core";
import * as rollsOfDayActions from "@app/store/actions/rolls-of-day.action";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { ApiService } from "@shop-services/index";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

@Injectable()
export class RollsOfDayEffects {
  
  loadRollsOfDay$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(rollsOfDayActions.LOAD_ROLLSOFDAY))
    .pipe(
      switchMap(() => {
        return this.apiService.getRollsOfDay().pipe(
          map(
            (rollsOfDay) =>
              new rollsOfDayActions.LoadRollsOfDaySuccess(rollsOfDay)
          ),
          catchError((error) =>
            of(new rollsOfDayActions.LoadRollsOfDayFail(error))
          )
        );
      })
    ));

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
