import { Action } from "@ngrx/store";
import { Product } from "@shop-models/index";

export const LOAD_PRODUCTS_LIST = "[Products] Load products";
export const LOAD_PRODUCTS_LIST_FAIL = "[Products] Load products fail";
export const LOAD_PRODUCTS_LIST_SUCCESS = "[Products] Load products success";
export const LIKE_PRODUCT = "[Products] Like product";
export const REMOVE_LIKED_PRODUCT = "[Products] Remove liked product";
export const GET_LIKED_PRODUCTS = "[Products] Get liked products";

export class LoadProducts implements Action {
  readonly type = LOAD_PRODUCTS_LIST;
}

export class LoadProductsFail implements Action {
  readonly type = LOAD_PRODUCTS_LIST_FAIL;
  constructor(public payload: any) {}
}

export class LoadProductsSuccess implements Action {
  readonly type = LOAD_PRODUCTS_LIST_SUCCESS;
  constructor(public payload: Array<Product>) {}
}

export class LikeProduct implements Action {
  readonly type = LIKE_PRODUCT;
  constructor(public payload: string) {}
}

export class RemoveLikedProduct implements Action {
  readonly type = REMOVE_LIKED_PRODUCT;
  constructor(public payload: string) {}
}

export class GetLikedProducts implements Action {
  readonly type = GET_LIKED_PRODUCTS;
  constructor() {}
}

export type ProductsActions =
  | LoadProducts
  | LoadProductsFail
  | LoadProductsSuccess
  | LikeProduct
  | RemoveLikedProduct
  | GetLikedProducts;
