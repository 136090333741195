<div fxFlex fxLayout="column" class="products-list">
  <ng-container
    *ngIf="
      mediaObserver.isActive('lt-md') || isTablet;
      then mobileView;
      else pcView
    "
  >
  </ng-container>

  <!-- Mobile template -->
  <ng-template #mobileView>
    <ng-container
      *ngIf="!(categoriesLoaded$ | async); then loader; else content"
    >
    </ng-container>

    <!-- LOADER -->
    <ng-template #loader>
      <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>

    <!-- CONTENT -->
    <ng-template #content>
      <ng-container
        *ngIf="
          selectedCategory$ | async;
          then haveProductsMobile;
          else noProductsMobile
        "
      >
      </ng-container>
      <ng-template #haveProductsMobile>
        <app-products-category [id]="(selectedCategory$ | async)?.id">
        </app-products-category>
      </ng-template>

      <ng-template #noProductsMobile>
        <mat-toolbar>
          <h2 class="mat-subheading-1" style="text-align: center; margin: 20px">
            В этой категории нет продуктов :(
          </h2>
        </mat-toolbar>
      </ng-template>
    </ng-template>
  </ng-template>

  <!-- PC template -->
  <ng-template #pcView>
    <mat-toolbar
      color="primary"
      style="justify-content: center; cursor: pointer"
      (click)="scrollTop()"
    >
      <h1
        class="category-name"
        [ngClass.lt-md]="{ 'category-name_lt-md': true }"
      >
        {{ (selectedCategory$ | async)?.name }}
      </h1>
    </mat-toolbar>

    <ng-container
      *ngIf="!(categoriesLoaded$ | async); then loader; else content"
    >
    </ng-container>

    <!-- LOADER -->
    <ng-template #loader>
      <div class="spinner-wrapper">
        <mat-spinner></mat-spinner>
      </div>
    </ng-template>

    <!-- CONTENT -->
    <ng-template #content>
      <ng-container
        *ngIf="
          selectedCategory$ | async;
          then haveProductsPC;
          else noProductsPC
        "
      >
      </ng-container>

      <ng-template #noProductsPC>
        <mat-toolbar>
          <h2 class="mat-subheading-1" style="text-align: center; margin: 20px">
            В этой категории нет продуктов :(
          </h2>
        </mat-toolbar>
      </ng-template>

      <ng-template #haveProductsPC>
        <app-products-category [id]="(selectedCategory$ | async)?.id">
        </app-products-category>
      </ng-template>
    </ng-template>
  </ng-template>
</div>
