import { HttpClient, HttpEvent, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { apiUrl } from "@app/constants/constants";
import { LoadCategories } from "@app/store/actions/categories.action";
import { LoadProducts } from "@app/store/actions/products.action";
import { ShopState } from "@app/store/reducers";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

export interface DayRolls {
  dayIndex: number;
  price: number;
  products: Array<string>;
}

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private store: Store<ShopState>) {}

  loadCategories() {
    this.store.dispatch(new LoadCategories());
  }

  loadProductsList() {
    this.store.dispatch(new LoadProducts());
  }

  /* ORDER */
  sendOrder(order): Observable<any> {
    return this.http.post(`${apiUrl}/order`, order);
  }
  getOrder(id): Observable<any> {
    return this.http.get(`${apiUrl}/order/${id}`);
  }
  getOrderStatus(id): Observable<any> {
    return this.http.get(`${apiUrl}/order/${id}/status`);
  }
  /* */

  /* Roll of day */
  updateRollsOfDay(id, data): Observable<any> {
    return this.http.patch(`${apiUrl}/discounts/dayroll/${id}`, data);
  }
  removeRollsOfDay(id): Observable<any> {
    return this.http.delete(`${apiUrl}/discounts/dayroll/${id}`);
  }
  getRollsOfDay(): Observable<any> {
    return this.http.get(`${apiUrl}/discounts/dayroll`);
  }
  getAllRollsOfDay(): Observable<any> {
    return this.http.get(`${apiUrl}/discounts/dayroll/all`);
  }
  /* */

  /* discounts */
  createDiscount(data): Observable<any> {
    return this.http.post(`${apiUrl}/discount`, data);
  }
  updateDiscount(id, data): Observable<any> {
    return this.http.patch(`${apiUrl}/discount/${id}`, data);
  }
  /*   updateDiscountImage(id, data): Observable<any> {
    return this.http.patch(`${apiUrl}/discount/${id}`, data);
  } */

  updateDiscountImage(file: File, id): Observable<HttpEvent<any>> {
    const url = `${apiUrl}/discount/${id}/image`;
    const formData = new FormData();
    formData.append("discount", file);

    const req = new HttpRequest("PATCH", url, formData);
    return this.http.request(req);
  }

  removeDiscount(id): Observable<any> {
    return this.http.delete(`${apiUrl}/discount/${id}`);
  }
  getDiscounts(): Observable<any> {
    return this.http.get(`${apiUrl}/discount/all`);
  }
  /* */

  /*   async cleanCache(): Promise<any> {
    return await this.http.delete(`${apiUrl}/admin/cache`).toPromise();
  } */

  syncProducts(): Observable<any> {
    return this.http.post(`${apiUrl}/admin/products/sync`, "");
  }

  async createProduct(product): Promise<any> {
    return await this.http.post(`${apiUrl}/admin/product`, product).toPromise();
  }

  async updateProduct(product): Promise<any> {
    return await this.http
      .patch(`${apiUrl}/admin/product`, product)
      .toPromise();
  }

  async removeProduct(id): Promise<any> {
    return await this.http.delete(`${apiUrl}/admin/product/${id}`).toPromise();
  }

  async createCategory(category): Promise<any> {
    return await this.http
      .post(`${apiUrl}/admin/category`, category)
      .toPromise();
  }

  async updateCategory(category): Promise<any> {
    return await this.http
      .patch(`${apiUrl}/admin/category`, category)
      .toPromise();
  }

  async removeCategory(id): Promise<any> {
    return await this.http.delete(`${apiUrl}/admin/category/${id}`).toPromise();
  }
}
