import { Action } from "@ngrx/store";

export const SEND_ORDER = "[Order] Send order to server";
export const SEND_ORDER_SUCCESS = "[Order] Send order success!";
export const SEND_ORDER_FAIL = "[Order] Send order FAIL!";

export const GET_ORDER = "[Order] Getting order from server";
export const GET_ORDER_SUCCESS = "[Order] Get order success!";
export const GET_ORDER_FAIL = "[Order] Get order FAIL!";

export const GET_ORDER_STATUS = "[Order] Getting order status from server";
export const GET_ORDER_STATUS_SUCCESS = "[Order] Getting order status success!";
export const GET_ORDER_STATUS_FAIL = "[Order] Getting order status FAIL!";

// send order to server
export class SendOrder implements Action {
  readonly type = SEND_ORDER;
  constructor(public payload: any) {}
}

export class SendOrderSuccess implements Action {
  readonly type = SEND_ORDER_SUCCESS;
  constructor(public payload: any) {}
}

export class SendOrderFail implements Action {
  readonly type = SEND_ORDER_FAIL;
  constructor(public payload: any) {}
}

// save order to local storage
/* export class SaveOrder implements Action {
  readonly type = SAVE_ORDER;
  constructor(public payload: any) {}
}
 */
// get order info from server
export class GetOrder implements Action {
  readonly type = GET_ORDER;
  constructor(public payload: any) {}
}

export class GetOrderSuccess implements Action {
  readonly type = GET_ORDER_SUCCESS;
  constructor(public payload: any) {}
}

export class GetOrderFail implements Action {
  readonly type = GET_ORDER_FAIL;
  constructor(public payload: any) {}
}

export class GetOrderStatus implements Action {
  readonly type = GET_ORDER_STATUS;
  constructor(public payload: any) {}
}

export class GetOrderStatusSuccess implements Action {
  readonly type = GET_ORDER_STATUS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetOrderStatusFail implements Action {
  readonly type = GET_ORDER_STATUS_FAIL;
  constructor(public payload: any) {}
}

export type OrderActions =
  | SendOrder
  | SendOrderSuccess
  | SendOrderFail
  | GetOrder
  | GetOrderSuccess
  | GetOrderFail
  | GetOrderStatus
  | GetOrderStatusSuccess
  | GetOrderStatusFail;
