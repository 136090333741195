<ng-container *ngIf="discount">
  <div class="wrapper">
    <img [src]="discount.img" />
    <ng-container *ngIf="discount.description">
      <p class="mat-body description">
        <strong>Доп. информация: </strong>
        {{ discount.description }}
      </p>
    </ng-container>
    <button
      mat-stroked-button
      color="primary"
      mat-dialog-close
      style="height: 40px"
    >
      Назад
    </button>
  </div>
</ng-container>
