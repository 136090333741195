import { ChangeDetectionStrategy, Component } from "@angular/core";
import { minOrderSum } from "@app/constants/constants";

@Component({
  selector: "app-info-features",
  templateUrl: "./info-features.component.html",
  styleUrls: ["./info-features.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoFeaturesComponent {
  minOrderSum: number;

  constructor() {
    this.minOrderSum = minOrderSum;
  }
}
