import { ChangeDetectionStrategy, Component } from "@angular/core";
import * as fromStore from "@app/store";
import * as fromRollsOfDaySelectors from "@app/store/selectors/rolls-of-day.selectors";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "app-rolls-of-day",
  templateUrl: "./rolls-of-day.component.html",
  styleUrls: ["./rolls-of-day.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RollsOfDayComponent {
  rollsOfDayProducts$: Observable<Array<string>>;
  rollsOfDayExists$: Observable<number>;
  rollsOfDayPrice$: Observable<number>;

  constructor(private store: Store<fromStore.ShopState>) {
    this.rollsOfDayProducts$ = this.store.pipe(
      select(fromRollsOfDaySelectors.getRollsOfDayProducts)
    );

    this.rollsOfDayExists$ = this.store.pipe(
      select(fromRollsOfDaySelectors.getRollsOfDayExists)
    );

    this.rollsOfDayPrice$ = this.store.pipe(
      select(fromRollsOfDaySelectors.getRollsOfDayPrice)
    );
  }
}
