<div class="sort-menu-mobile">
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Сортировка продуктов"
    role="presentation"
  >
    <mat-icon class="sort-menu-icon">sort</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      [disabled]="SortEnum.ascPrice === (currentSort$ | async)"
      (click)="switchSort(SortEnum.ascPrice)"
    >
      <mat-icon>arrow_upward</mat-icon>
      <span>По цене</span>
    </button>
    <button
      mat-menu-item
      [disabled]="SortEnum.descPrice === (currentSort$ | async)"
      (click)="switchSort(SortEnum.descPrice)"
    >
      <mat-icon>arrow_downward</mat-icon>
      <span>По цене</span>
    </button>
    <button
      mat-menu-item
      [disabled]="SortEnum.ascName === (currentSort$ | async)"
      (click)="switchSort(SortEnum.ascName)"
    >
      <mat-icon>arrow_upward</mat-icon>
      <span>По названию</span>
    </button>
    <button
      mat-menu-item
      [disabled]="SortEnum.descName === (currentSort$ | async)"
      (click)="switchSort(SortEnum.descName)"
    >
      <mat-icon>arrow_downward</mat-icon>
      <span>По названию</span>
    </button>
  </mat-menu>
</div>
