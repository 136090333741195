<div
  *ngIf="carouselLength > 0"
  class="wrapper"
  (mouseover)="turnOffAutoPlay()"
  (mouseleave)="turnOnAutoPlay()"
  (swipeleft)="swipe($event.type)"
  (swiperight)="swipe($event.type)"
>
  <ng-container *ngFor="let item of items; let i = index">
    <img
      [@slideAnimation]="currentSlide === i ? 'active' : 'inactive'"
      [hidden]="currentSlide != i"
      [src]="item.img"
      class="slide"
      alt=""
      draggable="false"
    />
  </ng-container>
  <ng-container *ngIf="carouselLength > 1">
    <div class="nav-dots">
      <ng-container *ngFor="let item of items; let i = index">
        <span
          [class.active]="currentSlide === i"
          (click)="goToSlide(i)"
          class="dot"
        ></span>
      </ng-container>
    </div>
    <div class="nav-buttons">
      <div class="prev nav-button" (click)="prevSlide()">&#10094;</div>
      <div class="next nav-button" (click)="nextSlide()">&#10095;</div>
    </div>
  </ng-container>
</div>
