<div class="category" [ngClass.sm]="{ sm: true }" [ngClass.xs]="{ xs: true }">
  <ng-container
    *ngIf="mediaObserver.isActive('gt-sm'); then pcView; else mobileView"
  >
  </ng-container>
</div>

<ng-template #pcView>
  <app-sort-products></app-sort-products>
  <div
    *ngIf="displayProducts$ | async as displayProducts"
    class="products"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="0"
    (scrolled)="onScroll()"
    style="position: relative"
  >
    <app-product
      [id]="product.id"
      *ngFor="let product of displayProducts; trackBy: trackById"
    >
    </app-product>
  </div>
</ng-template>

<ng-template #mobileView>
  <app-sort-products></app-sort-products>
  <div
    *ngIf="displayProducts$ | async as displayProducts"
    class="products"
    infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    (scrolled)="onScroll()"
    style="position: relative; width: 100%"
  >
    <app-product
      [id]="product.id"
      *ngFor="let product of displayProducts; trackBy: trackById"
    >
    </app-product>
  </div>
</ng-template>
