<footer [ngClass.xs]="{ xs: true }">
  <div class="theme-toggle">
    <button
      mat-icon-button
      (click)="switchDartkTheme(false)"
      aria-label="Включить светлую тему оформления"
    >
      <mat-icon [ngClass]="{ active: !(isDarkTheme$ | async) }"
        >brightness_5</mat-icon
      >
    </button>
    <mat-slide-toggle
      #dark
      color="accent"
      aria-label="Переключить цветовую тему оформления"
      [checked]="isDarkTheme$ | async"
      (change)="switchDartkTheme($event.checked)"
    ></mat-slide-toggle>
    <button
      mat-icon-button
      (click)="switchDartkTheme(true)"
      aria-label="Включить тёмную тему оформления"
    >
      <mat-icon [ngClass]="{ active: isDarkTheme$ | async }"
        >brightness_3</mat-icon
      >
    </button>
  </div>

  <div
    style="
      display: flex;
      width: 100%;
      justify-content: space-around;
      flex-wrap: wrap;
    "
  >
    <div class="footer-list">
      <h2 class="mat-title footer-title"><strong>Информация: </strong></h2>
      <ul>
        <li>
          <mat-icon>home</mat-icon>
          <h2 class="mat-subheading-2 footer-text">
            Россия, Сыктывкар, Старовского 51 А
          </h2>
        </li>
        <li>
          <mat-icon>phone</mat-icon>
          <div style="display: flex; flex-direction: column">
            <h2
              class="mat-subheading-2 footer-text"
              style="margin-bottom: 5px; margin-left: 0"
            >
              <a [href]="'tel:' + phoneNumberFull">{{ phoneNumber }}</a>
            </h2>
            <h2 class="mat-subheading-2 footer-text" style="margin-left: 0">
              <a [href]="'tel:' + extraPhoneNumberFull">{{
                extraPhoneNumber
              }}</a>
            </h2>
          </div>
        </li>
        <li>
          <mat-icon>access_time</mat-icon>
          <h2 class="mat-subheading-2 footer-text">
            <p class="mat-body">ПН - ВС: 11:00 - 22:45</p>
          </h2>
        </li>
        <li>
          <mat-icon>email</mat-icon>
          <h2 class="mat-subheading-2 footer-text">
            <a
              href="mailto:origami_80@mail.ru"
              title="Отправить email"
              class="email-link"
              >origami_80@mail.ru</a
            >
          </h2>
        </li>

        <li>
          <mat-icon>loyalty </mat-icon>
          <h2 class="mat-subheading-2 footer-text">
            <a
              routerLink="/discounts"
              style="color: white; text-decoration: underline"
            >
              Наши акции
            </a>
          </h2>
        </li>

        <li>
          <mat-icon>insert_drive_file </mat-icon>
          <h2 class="mat-subheading-2 footer-text">
            <a
              routerLink="/privacy"
              style="color: white; text-decoration: underline"
              >Политика конфиденциальности</a
            >
          </h2>
        </li>
      </ul>
    </div>

    <div class="footer-list">
      <h2 class="mat-title footer-title"><strong>Мы в соц. сетях: </strong></h2>
      <ul class="social-links">
        <li>
          <a
            href="https://vk.com/or.igami2014"
            rel="noopener"
            title="Группа ВКонтакте"
            target="_blank"
            class="social-link"
          >
            <mat-icon
              svgIcon="vk"
              class="social-icon"
              style="color: #4c75a3"
            ></mat-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>

  <h3 class="mat-body-1 footer-text" style="text-align: center">
    Суши и роллы по приемлемым ценам отличного качества! Быстро и вкусно!
  </h3>
  <h3 class="mat-body-1 footer-text" style="text-align: center">
    Доставка роллов, суши, пиццы, удонов в Сыктывкаре. Здесь можно заказать
    вкусные блюда японской, китайской кухни по приемлемым ценам на дом или в
    офис, а также с самовывозом.
  </h3>
  <h3 class="mat-body-1 footer-text">{{ currentYear }} © {{ companyName }}</h3>

  <h4 class="designed-by">
    Разработка -
    <a href="https://lnaumov.ru" target="_blank" rel="noopener"
      >Наумов Леонид</a
    >
  </h4>
</footer>
