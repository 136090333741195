import { ChangeDetectionStrategy, Component } from "@angular/core";
import { companyPhoneNumberFull } from "@app/constants/constants";

@Component({
  selector: "app-call-button",
  templateUrl: "./call-button.component.html",
  styleUrls: ["./call-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallButtonComponent {
  phoneNumberFull: string;

  constructor() {
    this.phoneNumberFull = companyPhoneNumberFull;
  }
}
