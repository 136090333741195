import { isPlatformBrowser, isPlatformServer } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, Meta } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import { SwUpdate } from "@angular/service-worker";
import { metaTags } from "@app/constants";
import * as fromStore from "@app/store";
import * as fromProductsSelectors from "@app/store/selectors/products.selectors";
import * as fromShopCartSelectors from "@app/store/selectors/shop-cart.selectors";
import { Store, select } from "@ngrx/store";
import { environment } from "environments/environment";
import {
  NgcCookieConsentService,
  NgcStatusChangeEvent,
} from "ngx-cookieconsent";
import { combineLatest, filter, map, take } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  constructor(
    private updates: SwUpdate,
    private meta: Meta,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private store: Store<fromStore.ShopState>,
    private ccService: NgcCookieConsentService
  ) {
    const domain = isPlatformServer(platformId) ? "http://localhost:4000/" : "";
    // Register svg icons for social icons
    this.matIconRegistry.addSvgIcon(
      `vk`,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${domain}assets/icons/social/vk.svg`
      )
    );
    this.router.events.subscribe((event) => {
      if (environment.production && event instanceof NavigationEnd) {
        try {
          (<any>window).yaCounter48766664.hit(event.urlAfterRedirects);

          this.meta.updateTag({
            "og:url": metaTags.url + event.urlAfterRedirects,
          });
        } catch (error) {}
      }
    });
  }

  ngOnInit() {
    this.promptCookies();
    // this.pwaInstallPromptHandler();
    this.pwaUpdateHandler();

    const cartList$ = this.store.pipe(
      select(fromShopCartSelectors.getCartList)
    );
    const cartSum$ = this.store.pipe(select(fromShopCartSelectors.getCartSum));

    const productsEntities$ = this.store.pipe(
      select(fromProductsSelectors.getProductsListEntities)
    );

    combineLatest([cartList$, cartSum$, productsEntities$])
      .pipe(
        filter(
          ([cartList, cartSum, productsEntities]) =>
            cartList?.length > 0 &&
            cartSum > 0 &&
            Object.keys(productsEntities)?.length > 0
        ),
        take(1),
        map(([cartList, cartSum, productsEntities]) => {
          let actualCartSum = 0;

          cartList.forEach((cartItem) => {
            actualCartSum +=
              productsEntities[cartItem?.product?.id]?.price * cartItem.amount;
            if (
              cartItem?.product?.price !==
              productsEntities[cartItem?.product?.id]?.price
            ) {
              console.error("cart item price is updated");
              this.store.dispatch(new fromStore.ClearCart());
            }
          });

          if (cartSum !== actualCartSum) {
            console.error("cartSum !== actualCartSum", cartSum, actualCartSum);
            this.store.dispatch(new fromStore.ClearCart());
          }
        })
      )
      .subscribe();

    this.meta.addTags([
      {
        name: "og:site_name",
        content: metaTags.name,
      },
      {
        name: "og:url",
        content: metaTags.url,
      },
      {
        name: "og:title",
        content: metaTags.title,
      },
      {
        name: "og:description",
        content: metaTags.description,
      },
      {
        name: "og:phone_number",
        content: metaTags.phone,
      },
      {
        name: "og:email",
        content: metaTags.email,
      },
      {
        name: "og:country-name",
        content: metaTags.country,
      },
      {
        name: "og:image",
        content: metaTags.logo,
      },
      {
        name: "keywords",
        content: metaTags.tags,
      },
      {
        name: "twitter:site",
        content: metaTags.name,
      },
      {
        name: "twitter:title",
        content: metaTags.title,
      },
      {
        name: "twitter:description",
        content: metaTags.description,
      },
    ]);
  }

  pwaUpdateHandler(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // TODO: https://stackoverflow.com/questions/50520610/where-should-we-unsubscribe-from-angulars-swupdate-available
    // watch for PWA update availability
    this.updates.versionUpdates.subscribe((event) => {
      if (event.type === "VERSION_READY") {
        this.updates.activateUpdate();
      }
    });
  }

  pwaInstallPromptHandler(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }
    if ((navigator as any).standalone === false) {
      return;
      // This is an iOS device and we are in the browser
      /*         this.snackBar.open(
          'У вас есть возможность добавить наш сайт в виде приложения на свой смартфон. Это удобно!',
          '',
          {
            duration: 3000
          }
        ); */
    }

    if ((navigator as any).standalone === undefined) {
      // It's not iOS
      if (window.matchMedia("(display-mode: browser").matches) {
        // We are in the browser
        window.addEventListener("beforeinstallprompt", (event) => {
          event.preventDefault();
          if (this.dialog.openDialogs.length > 0) {
            return false;
          }
          const sb = this.snackBar.open(
            "Вы можете добавить наш сайт на рабочий стол!",
            "Добавить",
            { duration: 300000 }
          );
          sb.onAction().subscribe(() => {
            (event as any).prompt();
            (event as any).userChoice.then((result) => {
              if (result.outcome === "dismissed") {
                // TODO: Track no installation
              } else {
                this.snackBar.open(
                  "Спасибо! Теперь вы можете открывать сайт через иконку на рабочем столе!",
                  "ОК",
                  {
                    duration: 3000,
                  }
                );
              }
            });
          });
          return false;
        });
      }
    }
  }

  private promptCookies() {
    if (!this.ccService || !this.ccService.statusChange$) {
      return;
    }
    const isCookieAllowedLocalStorageKey = "IS_COOKIE_ALLOWED";
    const isCookieAllowed = localStorage.getItem(
      isCookieAllowedLocalStorageKey
    );

    if (isCookieAllowed === "true") {
      this.ccService.close(false);
    } else {
      this.ccService.open();
      this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
        if (event.status === "allow") {
          localStorage.setItem(isCookieAllowedLocalStorageKey, "true");
          this.ccService.destroy();
        } else {
          localStorage.removeItem(isCookieAllowedLocalStorageKey);
        }
      });
    }
  }
}
