<nav mat-tab-nav-bar style="background: white">
  <a
    mat-tab-link
    [routerLink]="'/' + nav.url"
    [active]="nav.url === (currentCategoryRoute$ | async)"
    *ngFor="let nav of navMenuElements"
  >
    {{ nav.name }}
  </a>
</nav>
