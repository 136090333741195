import * as fromProducts from "@app/store/actions/products.action";
import { Product } from "@shop-models/product.model";

export interface ProductsState {
  products: { [id: string]: Product };
  loaded: boolean;
  loading: boolean;
  likedProducts: Array<string>;
}

export const initialState = {
  products: {},
  loaded: false,
  loading: false,
  likedProducts: getLikedProductsFromStorage(),
};

export function reducer(
  state = initialState,
  action: fromProducts.ProductsActions
): ProductsState {
  switch (action.type) {
    case fromProducts.LOAD_PRODUCTS_LIST: {
      return {
        ...state,
        loading: true,
      };
    }

    case fromProducts.LOAD_PRODUCTS_LIST_SUCCESS: {
      const products = action.payload;

      const entities = products.reduce(
        (prods: { [id: string]: Product }, product: Product) => {
          return {
            ...prods,
            [product.id]: product,
          };
        },
        {
          ...state.products,
        }
      );

      return {
        ...state,
        loading: false,
        loaded: true,
        products: entities,
      };
    }

    case fromProducts.LOAD_PRODUCTS_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromProducts.GET_LIKED_PRODUCTS: {
      const likedProducts = getLikedProductsFromStorage();

      return {
        ...state,
        likedProducts,
      };
    }

    case fromProducts.LIKE_PRODUCT: {
      saveLikedProduct(action.payload);
      const likedProducts = getLikedProductsFromStorage();

      return {
        ...state,
        likedProducts,
      };
    }

    case fromProducts.REMOVE_LIKED_PRODUCT: {
      const likedProducts = removeLikedProduct(action.payload);

      return {
        ...state,
        likedProducts,
      };
    }

    default: {
      return state;
    }
  }
}

function getLikedProductsFromStorage(): string[] {
  try {
    if (!localStorage?.getItem("likedProducts")) {
      return [];
    }
    return JSON.parse(localStorage?.getItem("likedProducts"));
  } catch (error) {
    console.error(error);
    return [];
  }
}

function saveLikedProduct(id: string): void {
  try {
    const likedProducts = getLikedProductsFromStorage();
    if (likedProducts.includes(id)) {
      return;
    }

    likedProducts.push(id);

    localStorage?.setItem("likedProducts", JSON.stringify(likedProducts));
  } catch (error) {
    console.error(error);
  }
}

function removeLikedProduct(removingId: string): string[] {
  try {
    let likedProducts = getLikedProductsFromStorage();
    if (!likedProducts.includes(removingId)) {
      return;
    }

    likedProducts = likedProducts.filter((id) => id !== removingId);

    localStorage?.setItem("likedProducts", JSON.stringify(likedProducts));
    return likedProducts;
  } catch (error) {
    console.error(error);
    return;
  }
}

export const getProductsEntities = (state: ProductsState) => state.products;
export const getProductsEntitiesCount = (state: ProductsState) =>
  Object.keys(state.products).length;
export const getProductsLoading = (state: ProductsState) => state.loading;
export const getProductsLoaded = (state: ProductsState) => state.loaded;
export const getLikedProducts = (state: ProductsState) => state.likedProducts;
