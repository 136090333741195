import * as fromFeature from "@app/store/reducers";
import * as fromRollsOfDay from "@app/store/reducers/rolls-of-day.reducer";
import { createSelector } from "@ngrx/store";

export const getRollsOfDayState = createSelector(
  fromFeature.getShopState,
  (state: fromFeature.ShopState) => state.rollsOfDay
);

export const getRollsOfDayProducts = createSelector(
  getRollsOfDayState,
  fromRollsOfDay.getRollsOfDayProducts
);

export const getRollsOfDayExists = createSelector(
  getRollsOfDayProducts,
  (products) => {
    if (!products) {
      return 0;
    }
    return products.length;
  }
);

export const getRollsOfDayPrice = createSelector(
  getRollsOfDayState,
  fromRollsOfDay.getRollsOfDayPrice
);
