import * as fromFeature from "@app/store/reducers";
import * as fromDiscounts from "@app/store/reducers/discounts.reducer";
import { createSelector } from "@ngrx/store";

export const { selectAll } = fromDiscounts.discountsAdapter.getSelectors(
  fromFeature.getDiscountsState
);

export const getDiscountsListEntities = createSelector(
  fromFeature.getDiscountsState,
  fromDiscounts.getDiscountsEntities,
  (ids, entities) => {
    if (entities) {
      return Object.values(entities);
    }
  }
);
