<mat-drawer-container class="example-container">
  <mat-drawer
    *ngIf="mediaObserver.isActive('lt-md') || isTablet"
    mode="over"
    [opened]="utils.isMenuOpened"
    (closedStart)="utils.closeMenu()"
    style="position: fixed"
  >
    <a class="make-call" [href]="'tel:' + phoneNumberFull">
      <mat-icon style="color: #d32f2f">phone</mat-icon>
      <div class="text">
        <p class="mat-subheading-2">{{ phoneNumber }}</p>
        <span>Позвонить</span>
      </div>
      <mat-icon style="margin-left: auto; color: #888787"
        >keyboard_arrow_right</mat-icon
      >
    </a>

    <a
      class="make-call"
      *ngIf="url !== '/'"
      (click)="utils.closeMenu()"
      routerLink="/"
    >
      <mat-icon style="color: #d32f2f">home</mat-icon>
      <div class="text">
        <p class="mat-subheading-2">На главную</p>
      </div>
      <mat-icon style="margin-left: auto; color: #888787"
        >keyboard_arrow_right</mat-icon
      >
    </a>

    <div class="menu-wrapper">
      <div
        class="menu-item"
        *ngFor="let nav of navMenuElements"
        [routerLink]="'/' + nav.url"
        (click)="utils.closeMenu()"
      >
        <div class="item-content">
          <div [class]="'icon ' + 'origami-' + nav.icon"></div>
          <p class="mat-body">{{ nav.name }}</p>
        </div>
      </div>
    </div>

    <a class="make-call" routerLink="/discounts" (click)="utils.closeMenu()">
      <mat-icon style="color: #d32f2f">loyalty</mat-icon>
      <div class="text">
        <p class="mat-subheading-2">Акции и скидки</p>
      </div>
      <mat-icon style="margin-left: auto; color: #888787"
        >keyboard_arrow_right</mat-icon
      >
    </a>
  </mat-drawer>

  <div
    class="wrapper"
    [ngClass.xs]="{ xs: true }"
    [ngClass.sm]="{ sm: true }"
    [class.origami-dark-theme]="isDarkTheme$ | async"
  >
    <app-sidenav-menu
      *ngIf="mediaObserver.isActive('gt-sm') && !isTablet"
    ></app-sidenav-menu>
    <div class="sub-wrapper">
      <div
        class="header-wrapper"
        [ngClass]="{ mobile: mediaObserver.isActive('lt-md') || isTablet }"
      >
        <app-header
          [ngClass]="{ mobile: mediaObserver.isActive('lt-md') || isTablet }"
        ></app-header>
        <app-mobile-navigation
          *ngIf="mediaObserver.isActive('lt-md') || isTablet"
        >
        </app-mobile-navigation>
      </div>

      <router-outlet> </router-outlet>
    </div>
  </div>
  <ng-container *ngIf="isOrderExist$ | async">
    <button
      [routerLink]="['/myOrders']"
      *ngIf="orderId"
      mat-raised-button
      color="accent"
      class="order"
    >
      Мой заказ
    </button>
  </ng-container>
  <app-cart></app-cart>
  <app-footer></app-footer>
</mat-drawer-container>
