<mat-horizontal-stepper
  linear="true"
  [ngClass]="{ dark: isDarkTheme$ | async }"
>
  <mat-step [completed]="isOnline$ | async">
    <ng-template matStepLabel>Заказ</ng-template>
    <ng-container
      *ngIf="(isOnline$ | async) !== null && (isOnline$ | async) === false"
    >
      <app-offline-message></app-offline-message>
    </ng-container>

    <app-cart-list></app-cart-list>
    <h2 class="mat-title sum">Итого: {{ cartSum$ | async }} ₽</h2>
    <div *ngIf="orderType.value === 'delivery'" class="fuel">
      <strong>+ 50₽ доставка</strong>
    </div>

    <h4
      class="mat-caption min-sum-warning"
      *ngIf="minOrderSum > (cartSum$ | async)"
    >
      Минимальная сумма для доставки: {{ minOrderSum }} руб.
    </h4>

    <h4 class="mat-caption prepay-warning">
      Друзья! При заказе впервые мы просим предоплату в размере 300 рублей.
      Спасибо за понимание <span class="color-red">❤</span>
    </h4>

    <div class="nav-bottom">
      <button
        type="button"
        style="min-width: 30px; font-size: 16px"
        mat-stroked-button
        color="warn"
        mat-dialog-close
      >
        Отмена
      </button>
      <button
        [disabled]="!(isOnline$ | async)"
        type="button"
        style="margin-right: 10px"
        class="navigation-button"
        color="primary"
        mat-raised-button
        matStepperNext
      >
        Далее
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="orderForm">
    <ng-template matStepLabel>Адрес</ng-template>

    <ng-container
      *ngIf="
        (orderSending$ | async) || (orderSent$ | async);
        then sendingOrder;
        else form
      "
    >
    </ng-container>

    <ng-template #sendingOrder>
      <ng-container *ngIf="orderSent$ | async; then sent; else spinner">
      </ng-container>

      <ng-template #spinner>
        <div class="spinner-wrapper">
          <mat-spinner> </mat-spinner>
        </div>
      </ng-template>

      <ng-template #sent>
        <div style="text-align: center">
          <h2 class="mat-title">{{ name.value }}, спасибо!</h2>
          <h2 class="mat-title">
            Ваш заказ принят. Если вы заказываете в <strong>первый раз</strong>,
            оператор вам перезвонит. Постоянным клиентам оператор не
            перезванивает.
          </h2>
          <p class="mat-body-1">
            Номер вашего заказа -
            <strong>{{ (order$ | async).number }}</strong>
          </p>

          <p class="mat-body" style="margin-top: 10px">
            Следите за статусом вашего заказа:
          </p>
          <button
            mat-raised-button
            mat-dialog-close
            color="accent"
            [routerLink]="['/myOrders']"
          >
            Мой заказ
          </button>
        </div>
      </ng-template>
    </ng-template>

    <ng-template #form>
      <ng-container *ngIf="orderError$ | async as orderError">
        <div class="error">
          <h2 class="mat-title">
            Извините, во время отправки заказа произошла ошибка! 😥
          </h2>
          <h3 class="mat-subheading-2">
            Мы готовы принять Ваш заказ по телефону.
          </h3>
          <app-call-button></app-call-button>
          <p class="mat-caption">{{ orderError.statusText }}</p>

          <h3 class="mat-subheading-2">Ваш заказ:</h3>
          <app-cart-list></app-cart-list>

          <p class="mat-body-1" *ngIf="orderDateType.value === 'pickup'">
            <strong>Самовывоз</strong>
          </p>

          <p class="mat-body-1">
            Итого: <strong> {{ cartSum$ | async }} руб.</strong>
          </p>
          <div *ngIf="orderType.value === 'delivery'" class="fuel">
            <strong>+ 50₽ доставка</strong>
          </div>
        </div>
      </ng-container>

      <form class="order-form" (ngSubmit)="onSubmit()" [formGroup]="orderForm">
        <mat-form-field>
          <mat-select
            placeholder="Доставить к:"
            formControlName="orderDateType"
          >
            <mat-option value="now"> Сейчас (по готовности) </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="orderDateType.value === 'preorder'">
          <mat-label>Дата предзаказа</mat-label>
          <input
            matInput
            readonly
            formControlName="date"
            [matDatepicker]="picker"
            [min]="minDate"
            [max]="maxDate"
            (click)="picker.open()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker
            #picker
            type="datetime"
            [twelveHour]="false"
          ></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <span matPrefix>+7 &nbsp;</span>
          <input
            type="number"
            placeholder="Номер телефона"
            formControlName="phone"
            matInput
          />
          <mat-error *ngIf="phone.hasError('numberlength')">
            Длина телефона -
            <strong>{{ phoneLength }} символов.</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input formControlName="name" matInput placeholder="Ваше имя" />
          <mat-error *ngIf="name.hasError('minlength')">
            Минимальная длина -
            <strong>{{ nameMinLength }} символа.</strong>
          </mat-error>
          <mat-error *ngIf="name.hasError('maxlength')">
            Максимальная длина -
            <strong>{{ nameMaxLength }} символов.</strong> Сейчас используется
            <strong>{{ name.value.length }}.</strong>
          </mat-error>
          <mat-error *ngIf="name.hasError('required')">
            <strong>Обязательное поле.</strong>
          </mat-error>
        </mat-form-field>

        <mat-button-toggle-group
          #type="matButtonToggleGroup"
          formControlName="orderType"
          style="margin-top: 15px"
        >
          <mat-button-toggle value="delivery">
            <b>Доставка</b>
          </mat-button-toggle>
          <mat-button-toggle value="pickup">
            <b>Самовывоз</b>
          </mat-button-toggle>
        </mat-button-toggle-group>

        <ng-container *ngIf="type.value === 'delivery'">
          <mat-form-field>
            <input formControlName="street" matInput placeholder="Улица" />
            <mat-error *ngIf="street.hasError('minlength')">
              Минимальная длина -
              <strong>{{ streetMinLength }} символа.</strong>
            </mat-error>
            <mat-error *ngIf="street.hasError('maxlength')">
              Максимальная длина -
              <strong>{{ streetMaxLength }} символов.</strong> Сейчас
              используется
              <strong>{{ street.value.length }}.</strong>
            </mat-error>
            <mat-error *ngIf="street.hasError('required')">
              <strong>Обязательное поле.</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              formControlName="houseNumber"
              matInput
              placeholder="Номер дома"
            />
            <mat-error *ngIf="houseNumber.hasError('minlength')">
              Минимальная длина -
              <strong>{{ houseNumberMinLength }} символ.</strong>
            </mat-error>
            <mat-error *ngIf="houseNumber.hasError('maxlength')">
              Максимальная длина -
              <strong>{{ houseNumberMaxLength }} символов.</strong> Сейчас
              используется
              <strong>{{ houseNumber.value.length }}.</strong>
            </mat-error>
            <mat-error *ngIf="houseNumber.hasError('required')">
              <strong>Обязательное поле.</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              [max]="entranceMax"
              [min]="entranceMin"
              type="number"
              formControlName="entrance"
              matInput
              placeholder="Подъезд"
            />
            <mat-error *ngIf="entrance.hasError('min')">
              Минимальный номер подъезда -
              <strong>{{ entranceMin }}.</strong>
            </mat-error>
            <mat-error *ngIf="entrance.hasError('max')">
              Максимальный номер подъезда -
              <strong>{{ entranceMax }}.</strong>
            </mat-error>
            <mat-error *ngIf="entrance.hasError('required')">
              <strong>Обязательное поле.</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              [max]="floorMax"
              [min]="floorMin"
              type="number"
              formControlName="floor"
              matInput
              placeholder="Этаж"
            />
            <mat-error *ngIf="floor.hasError('min')">
              Минимальный этаж -
              <strong>{{ floorMin }}.</strong>
            </mat-error>
            <mat-error *ngIf="floor.hasError('max')">
              Максимальный этаж -
              <strong>{{ floorMax }}.</strong>
            </mat-error>
            <mat-error *ngIf="floor.hasError('required')">
              <strong>Обязательное поле.</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input
              formControlName="apartment"
              matInput
              placeholder="Квартира/офис"
            />
            <mat-error *ngIf="apartment.hasError('maxlength')">
              Максимальная длина -
              <strong>{{ apartmentMaxLength }} символов.</strong> Сейчас
              используется
              <strong>{{ apartment.value.length }}.</strong>
            </mat-error>
            <mat-error *ngIf="apartment.hasError('required')">
              <strong>Обязательное поле.</strong>
            </mat-error>
          </mat-form-field>
        </ng-container>

        <mat-form-field>
          <input
            [max]="personsMax"
            [min]="personsMin"
            type="number"
            formControlName="persons"
            matInput
            placeholder="Количество персон"
          />
          <mat-error *ngIf="persons.hasError('min')">
            Минимальное кол-во персон -
            <strong>{{ personsMin }}.</strong>
          </mat-error>
          <mat-error *ngIf="persons.hasError('max')">
            Максимальное кол-во персон -
            <strong>{{ personsMax }}.</strong>
          </mat-error>
          <mat-error *ngIf="persons.hasError('required')">
            <strong>Обязательное поле.</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-select formControlName="pay" placeholder="Оплата">
            <mat-option value="2">Картой</mat-option>
            <mat-option value="1">Наличными</mat-option>
          </mat-select>
          <mat-error *ngIf="pay.hasError('required')">
            <strong>Обязательное поле.</strong>
          </mat-error>
        </mat-form-field>

        <ng-container *ngIf="pay.value == 1">
          <mat-form-field *ngIf="!orderForm.value.exact">
            <input
              [max]="changeMax"
              [min]="changeMin"
              type="number"
              formControlName="change"
              matInput
              placeholder="С какой купюры приготовить сдачу?"
            />
            <mat-error *ngIf="change.hasError('min')">
              Минимальная сумма -
              <strong>{{ changeMin }} руб.</strong>
            </mat-error>
            <mat-error *ngIf="change.hasError('max')">
              Максимальная сумма -
              <strong>{{ changeMax }} руб.</strong>
            </mat-error>
            <mat-error *ngIf="change.hasError('required')">
              <strong>Обязательное поле.</strong>
            </mat-error>
          </mat-form-field>
          <mat-checkbox
            formControlName="exact"
            color="warn"
            style="margin-top: 15px"
            >У меня ровно ({{ cartSum$ | async }} руб.)</mat-checkbox
          >
        </ng-container>

        <p *ngIf="orderDateType.value === 'preorder'">
          <strong>Предзаказы принимаем по 100% предоплате.</strong> При
          необходимости, пожалуйста, указывайте временной интервал не менее 30
          минут в комментарии. Например, "С 21:30 до 22:00".
        </p>

        <mat-form-field>
          <input
            formControlName="comment"
            matInput
            placeholder="Комментарий к заказу"
          />
          <mat-error *ngIf="comment.hasError('maxlength')">
            Максимальная длина -
            <strong>{{ commentMaxLength }} символов.</strong> Сейчас
            используется
            <strong>{{ comment.value.length }}.</strong>
          </mat-error>
        </mat-form-field>

        <h2 class="mat-title sum">Итого: {{ cartSum$ | async }} ₽</h2>
        <div *ngIf="orderType.value === 'delivery'" class="fuel">
          <strong>+ 50₽ доставка</strong>
        </div>

        <div class="nav-bottom">
          <button
            type="button"
            style="min-width: 30px; font-size: 16px"
            mat-stroked-button
            matStepperPrevious
          >
            Назад
          </button>

          <button
            type="submit"
            [disabled]="!(orderForm.valid && (cartSum$ | async) > 0)"
            class="navigation-button"
            mat-raised-button
            color="warn"
          >
            Отправить
          </button>
        </div>

        <p
          class="mat-body"
          style="font-size: 13px; text-align: center; color: #5fc6f7"
        >
          Нажимая на кнопку "Отправить", я даю согласие на обработку своих
          персональных данных и соглашаюсь c
          <a routerLink="/privacy" target="_blank"
            >политикой конфиденциальности</a
          >.
        </p>

        <span class="hint" *ngIf="!orderForm.valid"
          >Не все обязательные поля заполнены</span
        >
      </form>
    </ng-template>
  </mat-step>
</mat-horizontal-stepper>
