import { Component, Input, OnInit } from "@angular/core";
import { ShopState } from "@app/store";
import {
  LikeProduct,
  RemoveLikedProduct,
} from "@app/store/actions/products.action";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-like-button",
  templateUrl: "./like-button.component.html",
  styleUrls: ["./like-button.component.scss"],
})
export class LikeButtonComponent implements OnInit {
  @Input()
  liked: boolean;

  @Input()
  id: string;

  constructor(private store: Store<ShopState>) {}

  ngOnInit() {}

  toggle() {
    if (this.liked) {
      this.removeLikedProduct();
    } else {
      this.likeProduct();
    }
  }

  likeProduct() {
    this.store.dispatch(new LikeProduct(this.id));
  }

  removeLikedProduct() {
    this.store.dispatch(new RemoveLikedProduct(this.id));
  }
}
