import { Injectable } from "@angular/core";
import * as discountsActions from "@app/store/actions/discounts.action";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { ApiService } from "@shop-services/api.service";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

@Injectable()
export class DiscountsEffects {
  
  loadDiscounts$: Observable<Action> = createEffect(() => this.actions$
    .pipe(ofType(discountsActions.LOAD_DISCOUNTS_LIST))
    .pipe(
      switchMap(() => {
        return this.apiService.getDiscounts().pipe(
          map(
            (discounts) => new discountsActions.LoadDiscountsSuccess(discounts)
          ),
          catchError((error) =>
            of(new discountsActions.LoadDiscountsFail(error))
          )
        );
      })
    ));

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
