import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { fromEvent, merge, of } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  menuOpened = false;
  days: Array<string> = [
    "Воскресенье",
    "Понедельник",
    "Вторник",
    "Среда",
    "Четверг",
    "Пятница",
    "Суббота",
  ];

  constructor(
    private deviceService: DeviceDetectorService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  getDayOfWeekName(index?: number) {
    if (index) {
      return this.days[index];
    }
    const now = new Date();
    return this.days[now.getDay()];
  }

  scrollTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

  get isMenuOpened() {
    return this.menuOpened;
  }

  openMenu() {
    this.menuOpened = true;
  }

  closeMenu() {
    this.menuOpened = false;
  }

  get online$() {
    if (isPlatformBrowser(this.platformId)) {
      return merge(
        of(null),
        fromEvent(window, "online"),
        fromEvent(window, "offline")
      ).pipe(map(() => navigator.onLine));
    }
  }

  get isTablet(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      return this.deviceService.isTablet();
    }
  }
}
