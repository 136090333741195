import { Sort, Theme } from "@app/constants/constants";
import * as fromGlobals from "@app/store/actions/globals.action";

export interface GlobalsState {
  theme: Theme;
  productsSort: Sort;
}

export const initialState: GlobalsState = {
  theme: Theme.light,
  productsSort: Sort.ascName,
};

export function reducer(
  state = initialState,
  action: fromGlobals.GlobalsActions
): GlobalsState {
  switch (action.type) {
    case fromGlobals.SET_PRODUCTS_SORT: {
      return {
        ...state,
        productsSort: action.payload,
      };
    }

    case fromGlobals.SET_THEME: {
      return {
        ...state,
      };
    }

    case fromGlobals.SET_THEME_SUCCESS: {
      if (state.theme === action.payload) {
        return { ...state };
      }
      return {
        ...state,
        theme: action.payload,
      };
    }

    case fromGlobals.SET_THEME_FAIL: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
export const getGlobalsTheme = (state: GlobalsState) => state.theme;
export const getGlobalsProductsSort = (state: GlobalsState) =>
  state.productsSort;
