import { ChangeDetectionStrategy, Component } from "@angular/core";
import * as fromStore from "@app/store";
import * as fromShopCartSelectors from "@app/store/selectors/shop-cart.selectors";
import { select, Store } from "@ngrx/store";
import { CartItem, Product } from "@shop-models/index";
import { ProductsService, ThemeService } from "@shop-services/index";
import { Observable } from "rxjs";

@Component({
  selector: "app-cart-list",
  templateUrl: "./cart-list.component.html",
  styleUrls: ["./cart-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartListComponent {
  cartList$: Observable<CartItem[]>;

  constructor(
    private store: Store<fromStore.ShopState>,
    private theme: ThemeService,
    private productsService: ProductsService
  ) {
    this.cartList$ = this.store.pipe(select(fromShopCartSelectors.getCartList));
  }

  addToCart(product): void {
    this.store.dispatch(new fromStore.AddToCart(product));
  }

  removeFromCart(product): void {
    this.store.dispatch(new fromStore.RemoveFromCart(product));
  }

  get isDarkTheme$(): Observable<boolean> {
    return this.theme.isDark$;
  }

  getCompressedImage(product: Product): string {
    return this.productsService.getCompressedImagePath(product);
  }

  getOriginalImage(product: Product): string {
    return this.productsService.getOriginalImagePath(product);
  }
}
