import * as fromFeature from "@app/store/reducers";
import { createSelector } from "@ngrx/store";

export const getOrderState = createSelector(
  fromFeature.getShopState,
  (state: fromFeature.ShopState) => state.order
);

export const getOrderId = createSelector(getOrderState, (state) => {
  return state.id;
});

export const getOrderNumber = createSelector(getOrderState, (state) => {
  return state.number;
});

export const getOrderEntity = createSelector(getOrderState, (state) => {
  return state.order;
});

export const getIsOrderExistAndNotOutdated = createSelector(
  getOrderState,
  (state) => {
    return (
      Object.keys(state.order).length > 0 &&
      Date.now() < state.order.createdAt + 12 * 3600 * 1000 // order is outdated if exceeds 12 hours
    );
  }
);

export const getOrderSending = createSelector(getOrderState, (state) => {
  return state.sending;
});

export const getOrderSent = createSelector(getOrderState, (state) => {
  return state.sent;
});

export const getOrderError = createSelector(getOrderState, (state) => {
  return state.error;
});

export const getOrderStatus = createSelector(getOrderState, (state) => {
  return state.status;
});
