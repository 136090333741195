<mat-list
  role="list"
  class="cart-list"
  [ngClass]="{ dark: isDarkTheme$ | async }"
>
  <mat-list-item role="listitem" *ngFor="let item of cartList$ | async">
    <div
      class="item-wrapper"
      style="display: flex; flex-direction: column"
      *ngIf="item.product as product"
    >
      <div class="description">
        <ng-container *ngIf="isDarkTheme$ | async; then dark; else light">
        </ng-container>

        <ng-template #light>
          <img [src]="getCompressedImage(product)" />
        </ng-template>

        <ng-template #dark>
          <img [src]="getOriginalImage(product)" />
        </ng-template>

        <div class="description-text">
          <p class="mat-body-1" style="margin-bottom: 5px">
            {{ product.name }}
          </p>
          <span
            *ngIf="product.weight"
            class="mat-caption"
            style="color: #777777; display: block; font-size: 1rem"
          >
            {{ product.weight }} г</span
          >
        </div>
      </div>

      <div class="amount">
        <div class="amount-control">
          <button
            mat-icon-button
            [disableRipple]="true"
            (click)="removeFromCart(product)"
          >
            <mat-icon class="decrement">remove</mat-icon>
          </button>
          <p class="amount">
            <strong>{{ item.amount }}</strong>
          </p>
          <button
            mat-icon-button
            [disableRipple]="true"
            (click)="addToCart(product)"
          >
            <mat-icon color="primary" class="increment">add</mat-icon>
          </button>
        </div>
        <p>= {{ product.price * item.amount }} ₽</p>
      </div>
    </div>
  </mat-list-item>
</mat-list>
