<div
  class="wrapper"
  [ngClass.lt-sm]="{ 'lt-sm': true }"
  *ngIf="product$ | async as product"
>
  <mat-toolbar
    color="primary"
    style="margin: -24px; width: 100vw; position: sticky; top: -24px"
    *ngIf="mediaObserver.isActive('xs')"
  >
    <button mat-icon-button mat-dialog-close>
      <mat-icon>arrow_back</mat-icon>
    </button>
    <h1 class="mat-headline product-name">
      {{ product.name }}
    </h1>
  </mat-toolbar>

  <img
    class="product-image"
    [src]="getOriginalImage(product)"
    [alt]="product.name"
  />
  <app-like-button
    [liked]="isLiked$ | async"
    [id]="product.id"
    (click)="$event.stopPropagation()"
  ></app-like-button>
  <div class="description-wrapper">
    <h1 class="mat-headline product-name">
      {{ product.name }}
    </h1>
    <div style="margin-bottom: auto">
      <div class="price-weight">
        <div>
          <ng-container
            *ngIf="product.originalPrice; then discountPrice; else defaultPrice"
          >
          </ng-container>
        </div>

        <ng-template #discountPrice>
          <span
            style="text-decoration: line-through; color: #bdbdbd"
            matTooltip="Скидка по акции Ролл дня"
            matTooltipPosition="above"
            >{{ product.originalPrice }}</span
          >
          <h2 class="mat-title price">
            <b>{{ product.price }} ₽</b>
          </h2>
        </ng-template>

        <ng-template #defaultPrice>
          <h2 class="mat-title price">
            <b>{{ product.price }} ₽</b>
          </h2>
        </ng-template>

        <h3 *ngIf="product.weight" class="mat-subheading-2 weight">
          {{ product.weight }} г
        </h3>
      </div>

      <h3
        *ngIf="product.description"
        class="mat-subheading-2"
        class="description"
      >
        {{ product.description }}
      </h3>

      <ng-container *ngIf="product.tags">
        <mat-chip-list>
          <mat-chip
            *ngFor="let tag of product.tags"
            color="warn"
            selected="true"
            >{{ tag }}
          </mat-chip>
        </mat-chip-list>
      </ng-container>
    </div>

    <div class="amount-control-wrapper">
      <ng-container
        *ngIf="
          mediaObserver.isActive('xs');
          then closeButtonMobile;
          else closeButtonPC
        "
      >
      </ng-container>

      <ng-template #closeButtonMobile>
        <button mat-stroked-button mat-dialog-close color="warn">Назад</button>
      </ng-template>

      <ng-template #closeButtonPC>
        <button mat-button mat-dialog-close>Закрыть</button>
      </ng-template>

      <div [@visibilityChangedFromTop] style="transform-origin: center">
        <app-add-product-to-cart-button [product]="product">
        </app-add-product-to-cart-button>
      </div>
    </div>
  </div>
  <!--   <button mat-icon-button class="close-button" mat-dialog-close>
    <mat-icon aria-label="Закрыть">close</mat-icon>
  </button> -->
</div>
