import * as fromFeature from "@app/store/reducers";
import * as fromShopCart from "@app/store/reducers/shop-cart.reducer";
import { createSelector } from "@ngrx/store";

export const getCartState = createSelector(
  fromFeature.getShopState,
  (state: fromFeature.ShopState) => state.shopCart
);

export const getCartEntities = createSelector(
  getCartState,
  fromShopCart.getShopCartEntities
);

export const getProductCountById = (id) =>
  createSelector(getCartEntities, (entities): any => {
    return entities[id] ? entities[id].amount : 0;
  });

export const getCartList = createSelector(getCartEntities, (entities) => {
  return Object.values(entities);
});

export const getCartSum = createSelector(getCartState, (state) => {
  return state.sum;
});

export const getCartWeight = createSelector(getCartState, (state) => {
  let weight = 0;

  if (!state.entities) {
    return weight;
  }
  Object.values(state.entities).forEach((item) => {
    weight += item.amount * item.product.weight;
  });

  return weight;
});

export const getCartCount = createSelector(getCartState, (state) => {
  return state.count;
});
