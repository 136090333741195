import { ChangeDetectionStrategy, Component } from "@angular/core";
import { companyPhoneNumberFormatted } from "@app/constants/constants";

@Component({
  selector: "app-offline-message",
  templateUrl: "./offline-message.component.html",
  styleUrls: ["./offline-message.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfflineMessageComponent {
  phoneNumber: string;

  constructor() {
    this.phoneNumber = companyPhoneNumberFormatted;
  }
}
