import { animate, style, transition, trigger } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Meta, Title } from "@angular/platform-browser";
import {
  companyName,
  companyPhoneNumberFormatted,
  metaTags,
} from "@app/constants/constants";
import { ProductsService } from "@app/services";
import { ShopState } from "@app/store";
import {
  getLikedProductById,
  getSelectedProduct,
} from "@app/store/selectors/products.selectors";
import { select, Store } from "@ngrx/store";
import { Product } from "@shop-models/product.model";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("visibilityChangedFromTop", [
      transition(":enter", [
        style({
          opacity: 0.4,
          transform: "scale(0.5)",
        }),
        animate(
          "100ms linear",
          style({
            opacity: 1,
            transform: "scale(1)",
          })
        ),
      ]),
    ]),
    trigger("visibilityChangedFromBottom", [
      transition(":enter", [
        style({
          opacity: 0.4,
          transform: "scale(0.5)",
        }),
        animate(
          "75ms linear",
          style({
            opacity: 1,
            transform: "scale(1)",
          })
        ),
      ]),
    ]),
  ],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  product$: Observable<Product>;
  productsSub: Subscription;

  isLiked$: Observable<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<ShopState>,
    private meta: Meta,
    private title: Title,
    public mediaObserver: MediaObserver,
    private ref: ChangeDetectorRef,
    private productsService: ProductsService
  ) {}

  ngOnInit() {
    this.mediaObserver.asObservable().subscribe(() => this.ref.markForCheck());

    this.product$ = this.store.pipe(select(getSelectedProduct));

    this.productsSub = this.product$.subscribe((product) => {
      if (product) {
        const t = `${product.name} - ${companyName} | ${companyPhoneNumberFormatted}`;
        this.title.setTitle(t);
        this.meta.updateTag({
          name: "og:title",
          content: t,
        });
        if (product.img) {
          this.meta.updateTag({
            name: "og:image",
            content: product.img,
          });
        }

        this.isLiked$ = this.store.pipe(
          select(getLikedProductById(product.id))
        );
      }
    });
  }

  ngOnDestroy() {
    if (this.productsSub) {
      this.productsSub.unsubscribe();
    }

    this.title.setTitle(metaTags.title);

    this.meta.updateTag({
      name: "og:title",
      content: metaTags.title,
    });

    this.meta.updateTag({
      name: "og:image",
      content: metaTags.logo,
    });
  }

  getOriginalImage(product: Product): string {
    return this.productsService.getOriginalImagePath(product);
  }
}
