import { Overlay } from "@angular/cdk/overlay";
import { isPlatformBrowser } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  MatSnackBar,
  MatSnackBarRef,
  SimpleSnackBar,
} from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Theme,
  companyPhoneNumberFormatted,
  companyPhoneNumberFull,
  companyPhoneNumberFullFormatted,
  navElements,
} from "@app/constants/constants";
import * as fromStore from "@app/store";
import * as fromOrderSelectors from "@app/store/selectors/order.selectors";
import * as fromProductsSelectors from "@app/store/selectors/products.selectors";
import { Store, select } from "@ngrx/store";
import { ProductDetailsComponent } from "@shop-components/core/product-details/product-details.component";
import {
  LocalStorageService,
  ThemeService,
  UtilsService,
} from "@shop-services/index";
import { Observable, Subscription, of } from "rxjs";
import { distinctUntilChanged, filter } from "rxjs/operators";

@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedProductSub: Subscription;

  orderId: string;
  isOrderExist$: Observable<boolean>;

  navMenuElements: any;

  phoneNumber: string;
  phoneNumberShort: string;
  phoneNumberFull: string;

  SWIPE_ACTION = {
    LEFT: "swipeleft",
    RIGHT: "swiperight",
  };

  onload$: Observable<any>;

  isDarkTheme$: Observable<boolean>;
  isTablet: boolean;

  url: string;

  constructor(
    public mediaObserver: MediaObserver,
    private store: Store<fromStore.ShopState>,
    @Inject(PLATFORM_ID) private platformId: Object,
    private localStorageService: LocalStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private overlay: Overlay,
    private dialog: MatDialog,
    private theme: ThemeService,
    public utils: UtilsService,
    private ref: ChangeDetectorRef,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.url = this.router.url;

    this.loadCategories();
    this.loadProducts();
    this.loadRollsOfDay();
    this.loadDiscounts();

    this.phoneNumber = companyPhoneNumberFullFormatted;
    this.phoneNumberShort = companyPhoneNumberFormatted;
    this.phoneNumberFull = companyPhoneNumberFull;

    const isDarkTheme = this.localStorageService.get("dark") === "true";
    if (isDarkTheme) {
      this.store.dispatch(new fromStore.SetTheme(Theme.dark));
    }

    this.mediaObserver.asObservable().subscribe(() => this.ref.markForCheck());

    this.navMenuElements = navElements;
    this.orderId = this.localStorageService.get("orderId");

    this.isOrderExist$ = this.store.pipe(
      select(fromOrderSelectors.getIsOrderExistAndNotOutdated)
    );

    this.isDarkTheme$ = this.theme.isDark$;
    this.isTablet = this.utils.isTablet;

    let snackBarRef: MatSnackBarRef<SimpleSnackBar>;

    this.isOnline$.subscribe((isOnline) => {
      if (isOnline === null) {
        return;
      }
      if (isOnline === false) {
        snackBarRef = this.snackBar.open(
          `Вы не подключены к сети! Оставьте заказ по телефону ${this.phoneNumberShort}`,
          "ОК",
          {
            duration: 10000,
          }
        );
      } else {
        if (snackBarRef) {
          snackBarRef.dismiss();
        }
      }
    });

    // Load order status on startup if order id exists in localstorage
    if (this.orderId) {
      this.store.dispatch(new fromStore.GetOrder(this.orderId));
    }

    this.store
      .pipe(
        select(fromOrderSelectors.getOrderId),
        distinctUntilChanged(),
        filter((id) => !!id)
      )
      .subscribe((id) => {
        this.orderId = id;
        this.refreshOrderStatus(id);
      });

    // Load order status when order id change (when order form sent)
    if (this.orderId) {
      this.refreshOrderStatus(this.orderId);
    }
  }

  ngAfterViewInit() {
    // Open product details dialog if user came from direct url
    if (isPlatformBrowser(this.platformId)) {
      this.selectedProductSub = this.store
        .pipe(select(fromProductsSelectors.getSelectedProduct))
        .subscribe((product) => {
          let dialogRef: MatDialogRef<ProductDetailsComponent>;
          if (product) {
            dialogRef = this.dialog.open(ProductDetailsComponent, {
              panelClass: "product-details-dialog",
              autoFocus: false,
              data: {
                product: product,
              },
              height: "400px",
              width: "800px",
              maxWidth: "90vw",
              maxHeight: "100vh",
              scrollStrategy: this.overlay.scrollStrategies.block(),
            });
          }
          if (dialogRef) {
            dialogRef.beforeClosed().subscribe(() => {
              this.router.navigate([], {
                relativeTo: this.route,
              });
            });
          }
        });
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId) && this.selectedProductSub) {
      this.selectedProductSub.unsubscribe();
    }
  }

  // used to open menu with swipes, but conflict with iphone overflow: scroll
  /*   swipe(event): void {
    if (event.type === this.SWIPE_ACTION.LEFT) {
      this.utils.closeMenu();
    } else if (event.type === this.SWIPE_ACTION.RIGHT) {
      this.utils.openMenu();
    }
  }
 */

  refreshOrderStatus(id): void {
    this.store.dispatch(new fromStore.GetOrderStatus(id));
  }

  loadCategories(): void {
    this.store.dispatch(new fromStore.LoadCategories());
  }

  loadProducts(): void {
    this.store.dispatch(new fromStore.LoadProducts());
  }

  loadRollsOfDay(): void {
    this.store.dispatch(new fromStore.LoadRollsOfDay());
  }

  loadDiscounts(): void {
    this.store.dispatch(new fromStore.LoadDiscounts());
  }

  get isOnline$(): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      return this.utils.online$;
    } else {
      return of(null);
    }
  }
}
