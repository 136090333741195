import { Sort } from "@app/constants/constants";
import * as fromRoot from "@app/router/store/reducers";
import * as fromFeature from "@app/store/reducers";
import * as fromProducts from "@app/store/reducers/products.reducer";
import * as fromGlobals from "@app/store/selectors/globals.selectors";
import { createSelector } from "@ngrx/store";
import { Product } from "@shop-models/product.model";

export const getProductsListState = createSelector(
  fromFeature.getShopState,
  (state: fromFeature.ShopState) => state.products
);

export const getProductsListEntities = createSelector(
  getProductsListState,
  fromProducts.getProductsEntities
);

export const getProducts = createSelector(
  getProductsListEntities,
  (entities) => {
    const products = Object.values(entities);
    return products;
  }
);

export const getProductsSortedByNameAsc = createSelector(
  getProducts,
  (products) => {
    return products.sort(function (a, b) {
      return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    });
  }
);

export const getProductById = (id) =>
  createSelector(getProductsListEntities, (entities) => {
    return entities[id];
  });

export const getProductsByIds = (ids: Array<string>) =>
  createSelector(getProductsListEntities, (entities) => {
    const products: Array<Product> = [];
    ids.forEach((id) => {
      products.push(entities[id]);
    });
    return products;
  });

export const getProductsByCategoryId = (id) =>
  createSelector(
    getProducts,
    fromGlobals.getGlobalsProductsSort,
    (products, sort): Array<Product> => {
      products = products.filter((prod) => prod.category === id);

      switch (sort) {
        case Sort.ascName: {
          products.sort(function (a, b) {
            return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
          });
          break;
        }
        case Sort.descName: {
          products.sort(function (a, b) {
            return a.name < b.name ? 1 : b.name < a.name ? -1 : 0;
          });
          break;
        }

        case Sort.ascPrice: {
          products.sort(function (a, b) {
            return a.price - b.price;
          });
          break;
        }
        case Sort.descPrice: {
          products.sort(function (a, b) {
            return b.price - a.price;
          });
          break;
        }
      }

      return products;
    }
  );

export const getProductsSliceByCategoryId = (id, start, end) =>
  createSelector(
    getProductsByCategoryId(id),
    (products): Array<Product> => {
      if (products) {
        const slice = products.slice(start, end);
        return slice;
      }

      return products;
    }
  );

export const getSelectedProduct = createSelector(
  getProductsListEntities,
  fromRoot.getRouterState,
  (entities, router): Product => {
    if (router.state && entities[router.state.queryParams.productId]) {
      return entities[router.state.queryParams.productId];
    } else {
    }
  }
);

export const getLikedProducts = createSelector(
  getProductsListState,
  (state) => {
    return state.likedProducts;
  }
);

export const getLikedProductById = (id: string) =>
  createSelector(getLikedProducts, (ids) => {
    return ids.includes(id);
  });
