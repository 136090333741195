import { Action } from "@ngrx/store";
import { RollsOfDay } from "@shop-models/rolls-of-day.model";

export const LOAD_ROLLSOFDAY = "[RollsOfDay] Load rolls of day";
export const LOAD_ROLLSOFDAY_FAIL = "[RollsOfDay] Load rolls of day fail";
export const LOAD_ROLLSOFDAY_SUCCESS = "[RollsOfDay] Load rolls of day success";

export class LoadRollsOfDay implements Action {
  readonly type = LOAD_ROLLSOFDAY;
}

export class LoadRollsOfDayFail implements Action {
  readonly type = LOAD_ROLLSOFDAY_FAIL;
  constructor(public payload: any) {}
}

export class LoadRollsOfDaySuccess implements Action {
  readonly type = LOAD_ROLLSOFDAY_SUCCESS;
  constructor(public payload: RollsOfDay) {}
}

export type RollsOfDayActions =
  | LoadRollsOfDay
  | LoadRollsOfDayFail
  | LoadRollsOfDaySuccess;
