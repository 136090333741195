import { ProductsList } from "@shop-models/products-list.model";
import { Action } from "@ngrx/store";

// export const GET_PRODUCTS = '[Products ] Save';
export const LOAD_CATEGORIES = "[Categories] Load categories";
export const LOAD_CATEGORIES_FAIL = "[Categories] Load categories fail";
export const LOAD_CATEGORIES_SUCCESS = "[Categories] Load categories success";

export class LoadCategories implements Action {
  readonly type = LOAD_CATEGORIES;
}

export class LoadCategoriesFail implements Action {
  readonly type = LOAD_CATEGORIES_FAIL;
  constructor(public payload: any) {}
}

export class LoadCategoriesSuccess implements Action {
  readonly type = LOAD_CATEGORIES_SUCCESS;
  constructor(public payload: ProductsList) {}
}

export type CategoriesActions =
  | LoadCategories
  | LoadCategoriesFail
  | LoadCategoriesSuccess;
