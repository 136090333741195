<nav>
  <ul>
    <li
      class="item"
      *ngFor="let element of elements"
      [routerLink]="'/' + element.url"
    >
      <a [routerLink]="'/' + element.url">
        <app-sidenav-element [element]="element"></app-sidenav-element>
      </a>
    </li>
  </ul>
</nav>
