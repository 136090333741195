import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { interval, Subscription } from "rxjs";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("slideAnimation", [
      state(
        "inactive",
        style({
          opacity: 0.8,
        })
      ),
      state(
        "active",
        style({
          opacity: 1,
        })
      ),
      transition("inactive => active", animate("300ms ease-in")),
      transition("active => inactive", animate("300ms ease-out")),
    ]),
  ],
})
export class CarouselComponent implements OnInit, OnDestroy {
  currentSlide: number;
  autoPlaySub: Subscription;
  autoPlayInterval = 5000;

  mouseOver: boolean;
  carouselLength: number;

  items = [];

  SWIPE_ACTION = { LEFT: "swipeleft", RIGHT: "swiperight" };

  constructor(private ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.currentSlide = 0;
    this.autoPlay(true);
    this.carouselLength = this.items.length;
  }

  ngOnDestroy() {
    if (this.autoPlaySub) {
      this.autoPlaySub.unsubscribe();
    }
  }

  autoPlay(on: boolean) {
    if (on && this.carouselLength > 1) {
      this.turnOnAutoPlay();
    } else {
      this.turnOffAutoPlay();
    }
  }

  turnOnAutoPlay() {
    this.autoPlaySub = interval(this.autoPlayInterval).subscribe(() => {
      if (!this.mouseOver) {
        this.nextSlide();
      }
    });
  }

  turnOffAutoPlay() {
    if (this.autoPlaySub) {
      this.autoPlaySub.unsubscribe();
    }
  }

  goToSlide(id) {
    this.currentSlide = id;
  }

  nextSlide() {
    if (this.currentSlide === this.carouselLength - 1) {
      this.currentSlide = 0;
    } else {
      this.currentSlide++;
    }
    this.ref.markForCheck();
  }

  prevSlide() {
    if (this.currentSlide === 0) {
      this.currentSlide = this.carouselLength - 1;
    } else {
      this.currentSlide--;
    }
  }

  swipe(eType) {
    if (eType === this.SWIPE_ACTION.LEFT) {
      this.nextSlide();
    } else if (eType === this.SWIPE_ACTION.RIGHT) {
      this.prevSlide();
    }
  }
}
