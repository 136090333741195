import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from "@angular/core";
import { MediaChange, MediaObserver } from "@angular/flex-layout";
import { Meta, Title } from "@angular/platform-browser";
import { metaTags, minOrderSum } from "@app/constants/constants";
import * as fromStore from "@app/store";
import * as fromCategoriesSelectors from "@app/store/selectors/categories.selectors";
import { Store, select } from "@ngrx/store";
import { ProductsCategory } from "@shop-models/index";
import { UtilsService } from "@shop-services/index";
import { Observable } from "rxjs";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit, AfterViewInit {
  minOrderSum = minOrderSum;
  categories$: Observable<Array<ProductsCategory>>;

  screenChange$: Observable<MediaChange[]>;

  init: boolean;

  public dateTime: Date;
  constructor(
    public mediaObserver: MediaObserver,
    private meta: Meta,
    private title: Title,
    private store: Store<fromStore.ShopState>,
    private utils: UtilsService,
    private ref: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.screenChange$ = this.mediaObserver.asObservable();
    this.screenChange$.subscribe(() => this.ref.markForCheck());

    this.title.setTitle(metaTags.title);

    this.meta.updateTag({
      name: "og:title",
      content: metaTags.title,
    });

    this.meta.updateTag({
      name: "og:image",
      content: metaTags.logo,
    });

    this.meta.removeTag("category");

    this.categories$ = this.store.pipe(
      select(fromCategoriesSelectors.selectAll)
    );
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.init = true;
  }

  openMenu(): void {
    this.utils.openMenu();
  }

  get isOnline$(): Observable<boolean> {
    return this.utils.online$;
  }

  get isTablet(): boolean {
    return this.utils.isTablet;
  }
}
