import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";
import { Meta, Title } from "@angular/platform-browser";
import {
  companyName,
  companyPhoneNumberFormatted,
} from "@app/constants/constants";
import * as fromStore from "@app/store";
import * as fromCategoriesSelectors from "@app/store/selectors/categories.selectors";
import * as fromProductsSelectors from "@app/store/selectors/products.selectors";
import { select, Store } from "@ngrx/store";
import { Product } from "@shop-models/product.model";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

@Component({
  selector: "app-products-category",
  templateUrl: "./products-category.component.html",
  styleUrls: ["./products-category.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsCategoryComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  id: string;

  companyName: string;
  phoneNumber: string;

  lazyLoadStep: number;
  SWIPE_ACTION = {
    LEFT: "swipeleft",
    RIGHT: "swiperight",
  };
  scrollPositionSubject: BehaviorSubject<any>;
  scrollPosition: any;

  categoryEntitiesSubMeta: Subscription;
  scrollPositionSub: Subscription;

  displayProducts$: Observable<Array<Product>>;
  displayProductsSub: Subscription;

  constructor(
    public mediaObserver: MediaObserver,
    private store: Store<fromStore.ShopState>,
    private meta: Meta,
    private title: Title
  ) {
    this.companyName = companyName;
    this.phoneNumber = companyPhoneNumberFormatted;

    if (this.mediaObserver.isActive("xs")) {
      this.lazyLoadStep = 6;
    } else {
      this.lazyLoadStep = 16;
    }
  }

  ngOnInit() {
    this.initScrollPosition();

    this.categoryEntitiesSubMeta = this.store
      .pipe(select(fromCategoriesSelectors.getSelectedCategory))
      .subscribe((category) => {
        if (category) {
          if (this.mediaObserver.isActive("xs")) {
            this.initScrollPosition();
          }

          const title = `${category.name} - ${companyName} | ${companyPhoneNumberFormatted}`;

          this.title.setTitle(title);

          if (category.name) {
            this.meta.updateTag({
              name: "category",
              content: category.name,
            });

            this.meta.updateTag({
              name: "og:title",
              content: title,
            });
          }

          if (category.products) {
            this.meta.updateTag({
              name: "og:image",
              content: category.products[0].img,
            });
          }
        }
      });

    this.subscribeToScroll();
  }

  ngOnChanges() {
    this.subscribeToScroll();
  }
  ngOnDestroy() {
    if (this.categoryEntitiesSubMeta) {
      this.categoryEntitiesSubMeta.unsubscribe();
    }
    if (this.scrollPositionSub) {
      this.scrollPositionSub.unsubscribe();
    }
  }

  initScrollPosition(): void {
    this.scrollPositionSubject = new BehaviorSubject({
      start: 0,
      end: this.lazyLoadStep,
    });
  }

  subscribeToScroll(): void {
    if (this.scrollPositionSubject) {
      this.scrollPositionSub = this.scrollPositionSubject.subscribe(
        (newScrollPosition) => {
          this.scrollPosition = newScrollPosition;

          this.displayProducts$ = this.store.pipe(
            select(
              fromProductsSelectors.getProductsSliceByCategoryId(
                this.id,
                newScrollPosition.start,
                newScrollPosition.end
              )
            )
          );
        }
      );
    }
  }

  onScroll(): void {
    this.scrollPositionSubject.next({
      start: this.scrollPosition.start,
      end: this.scrollPosition.end + this.lazyLoadStep,
    });
  }

  trackById(index, product: Product): string {
    if (!product) {
      return null;
    }
    return product.id;
  }
}
