import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  apiUrl,
  productFallbackImage,
  productImagesUrl,
  productOriginalImagesUrl,
} from "@app/constants/constants";
import { Product } from "@shop-models/product.model";
import { ProductsList } from "@shop-models/products-list.model";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ProductsService {
  public productsList: any;

  constructor(private http: HttpClient) {}

  /**
   *
   * @description getting categories with products from server with filtering by category
   * @returns {Observable<ProductsList>}
   * @memberof ProductsService
   */
  getCategories(): Observable<ProductsList> {
    return this.http
      .get<any>(`${apiUrl}/products`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  /**
   *
   * @description getting plain products list from server as array
   * @returns {Observable<Array<Product>>}
   * @memberof ProductsService
   */
  getProducts(): Observable<Array<Product>> {
    return this.http
      .get<any>(`${apiUrl}/products/list`)
      .pipe(catchError((error: any) => throwError(error)));
  }

  /**
   *
   * @description return products list filtered by category
   * @param {ProductsList} productsList list of products that needs to be filtered
   * @param {number} id id of category that will be filtered(excluded)
   * @returns {Promise<ProductsList>}
   * @memberof ProductsService
   */
  async filterProductsListByCategoryId(
    productsList: any,
    id: number
  ): Promise<any> {
    if (!productsList) {
      console.log(
        "Empty productsList passed in filterProductsListByCategoryId"
      );
      return productsList;
    }

    const pList: any = new Array() as any;
    pList.push(productsList.find((category) => category.id === id));
    return pList;
  }

  /**
   *
   * @description return products list filtered by categories
   * @param {ProductsList} productsList list of products that needs to be filtered
   * @param {Array<number>} id  id of categories that will be filtered(excluded)
   * @returns {ProductsList}
   * @memberof ProductsService
   */
  filterCategoriesById(productsList: any, id: Array<number>): any {
    if (!productsList) {
      console.error("Empty productsList passed in filterCategoriesById");
      return productsList;
    }
    const pList: any = new Array() as any;
    productsList.filter((category) => {
      if (id.includes(category.id)) {
        pList.push(category);
      }
    });
    return pList;
  }

  /**
   *
   * @description return non-compressed image of product
   * @param {Product} product product instance
   * @returns {string}
   */
  getOriginalImagePath(product: Product): string {
    if (!product) {
      return;
    }
    if (!product.originalImg) {
      return this.getCompressedImagePath(product);
    }
    return productOriginalImagesUrl + product.originalImg;
  }

  /**
   *
   * @description return compressed image of product
   * @param {Product} product product instance
   */
  getCompressedImagePath(product: Product): string {
    if (!product || !product.img) {
      return productFallbackImage;
    }
    return productImagesUrl + product.img;
  }
}
