<div
  *ngIf="orderState$ | async as orderState"
  class="wrapper"
  [ngClass]="{ dark: isDarkTheme$ | async }"
>
  <mat-card>
    <mat-card-title>
      <p>
        Заказ № {{ orderState.number }} от
        {{ orderState.order.createdAt | date: "dd/MM/yyyy HH:mm" }}
      </p>
    </mat-card-title>

    <mat-card-subtitle>
      Статус заказа:
      <strong>{{ orderState.status }}</strong>
      <span *ngIf="orderState.date"> (изменён {{ orderState.date }})</span>
      <button
        mat-icon-button
        color="primary"
        (click)="refreshOrderStatus()"
        [matTooltip]="
          mediaObserver.isActive('gt-sm') ? 'Обновить статус заказа' : ''
        "
        aria-label="Обновить статус заказа"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </mat-card-subtitle>

    <mat-card-content>
      <h2 class="mat-title" *ngIf="orderState.order.phone">
        +7{{ orderState.order.phone }}
      </h2>
      <h2 class="mat-title" *ngIf="orderState.order.name">
        {{ orderState.order.name }}
      </h2>

      <ng-container *ngIf="orderState.order.orderType === 'pickup'">
        <h2 class="mat-title">Самовывоз (ул. Старовского, 51 А)</h2>
        <a
          href="https://yandex.ru/maps/19/syktyvkar/?z=16&ll=50.802892%2C61.664324&l=map&origin=jsapi_2_1_65&from=api-maps&mode=search&ol=biz&oid=215189231447"
          target="_blank"
          mat-raised-button
          color="primary"
          >Показать на Яндекс-картах
        </a>
      </ng-container>

      <ng-container *ngIf="orderState.order.orderType !== 'pickup'">
        <div class="field" *ngIf="orderState.order.street">
          <h3 class="mat-title title">
            <span class="mat-body-1 caption">Улица: </span>
            {{ orderState.order.street }}
          </h3>
        </div>

        <div class="field" *ngIf="orderState.order.houseNumber">
          <h3 class="mat-title title">
            <span class="mat-body-1 caption">Дом: </span>
            {{ orderState.order.houseNumber }}
          </h3>
        </div>

        <div class="field" *ngIf="orderState.order.entrance">
          <h3 class="mat-title title">
            <span class="mat-body-1 caption">Подъезд: </span>
            {{ orderState.order.entrance }}
          </h3>
        </div>

        <div class="field" *ngIf="orderState.order.floor">
          <h3 class="mat-title title">
            <span class="mat-body-1 caption">Этаж: </span>
            {{ orderState.order.floor }}
          </h3>
        </div>

        <div class="field" *ngIf="orderState.order.apartment">
          <h3 class="mat-title title">
            <span class="mat-body-1 caption">Квартира: </span>
            {{ orderState.order.apartment }}
          </h3>
        </div>
      </ng-container>

      <div class="field" *ngIf="orderState.order.persons">
        <h3 class="mat-title title">
          <span class="mat-body-1 caption">Количество персон: </span>
          {{ orderState.order.persons }}
        </h3>
      </div>

      <div class="field" *ngIf="orderState.order.pay">
        <h3 class="mat-title title" *ngIf="orderState.order.pay == 2">
          <span class="mat-body-1 caption">Оплата: </span>
          По карте
        </h3>
        <h3 class="mat-title title" *ngIf="orderState.order.pay == 1">
          <span class="mat-body-1 caption">Оплата: </span>
          Наличными
        </h3>
      </div>

      <div class="field" *ngIf="orderState.order.comment">
        <h3 class="mat-title title">
          <span class="mat-body-1 caption">Комментарий: </span>
          {{ orderState.order.comment }}
        </h3>
      </div>
      <hr />
      <h2 class="mat-title title">Заказ:</h2>

      <ng-container
        *ngIf="mediaObserver.isActive('lt-md'); then mobile; else pc"
      >
      </ng-container>

      <!-- PC View-->
      <ng-template #pc>
        <table mat-table [dataSource]="dataSource" matSort>
          <!--          <ng-container matColumnDef="frontpad_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Артикул </th>
            <td mat-cell *matCellDef="let product"> {{product.frontpad_id}} </td>
          </ng-container> -->

          <ng-container matColumnDef="img">
            <th mat-header-cell *matHeaderCellDef></th>
            <td
              mat-cell
              *matCellDef="let product"
              (click)="openProductDetails(product)"
              style="cursor: pointer"
            >
              <img
                style="height: 50px; margin-right: 20px"
                [src]="
                  (isDarkTheme$ | async)
                    ? getOriginalImage(product)
                    : getCompressedImage(product)
                "
                [alt]="product.name"
              />
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Название</th>
            <td
              mat-cell
              *matCellDef="let product"
              (click)="openProductDetails(product)"
              style="
                cursor: pointer;
                text-decoration: underline;
                text-decoration-color: steelblue;
              "
            >
              {{ product.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Цена</th>
            <td mat-cell *matCellDef="let product">{{ product.price }} ₽</td>
          </ng-container>

          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Кол-во</th>
            <td mat-cell *matCellDef="let product">{{ product.amount }}</td>
          </ng-container>

          <ng-container matColumnDef="sum">
            <th mat-header-cell *matHeaderCellDef>Стоимость</th>
            <td mat-cell *matCellDef="let product">
              {{ product.price * product.amount }} ₽
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </ng-template>
      <!---->

      <!-- Mobile View-->
      <ng-template #mobile>
        <mat-list role="list">
          <mat-list-item
            role="listitem"
            *ngFor="let product of products"
            style="margin: 20px 0"
          >
            <img
              style="height: 50px; margin-right: 20px"
              [src]="
                (isDarkTheme$ | async)
                  ? getOriginalImage(product)
                  : getCompressedImage(product)
              "
              [alt]="product.name"
            />
            {{ product.name }} ( <span>{{ product.amount }} шт.</span>) =
            {{ product.price * product.amount }} ₽
          </mat-list-item>
        </mat-list>
      </ng-template>
      <!---->

      <h2 class="mat-title title" style="text-align: right; margin-top: 20px">
        Сумма:
        <strong>{{ sum }} ₽</strong>
      </h2>
    </mat-card-content>
  </mat-card>

  <p class="mat-subheading-1">
    Будем рады Вашим отзывам в нашей группе ВКонтакте!
  </p>
  <a
    href="https://vk.com/or.igami2014"
    target="_blank"
    mat-raised-button
    color="accent"
    style="margin: 10px"
  >
    Перейти в группу ВК
  </a>
</div>
