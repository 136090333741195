import { Injectable } from "@angular/core";
import * as productsActions from "@app/store/actions/products.action";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as fromServices from "@shop-services/products.service";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

@Injectable()
export class ProductsEffects {
  
  loadProductsList$ = createEffect(() => this.actions$
    .pipe(ofType(productsActions.LOAD_PRODUCTS_LIST))
    .pipe(
      switchMap(() => {
        return this.productsService.getProducts().pipe(
          map((products) => new productsActions.LoadProductsSuccess(products)),
          catchError((error) => of(new productsActions.LoadProductsFail(error)))
        );
      })
    ));

  constructor(
    private actions$: Actions,
    private productsService: fromServices.ProductsService
  ) {}
}
