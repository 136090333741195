import { Discount } from "@shop-models/discount.model";
import { Component, Inject, ChangeDetectionStrategy } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-discount-details",
  templateUrl: "./discount-details.component.html",
  styleUrls: ["./discount-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountDetailsComponent {
  discount: Discount;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.discount = data.discount;
  }
}
